import { useEffect, useState } from "react";
import ComponentDrawer from "../../Shared/ComponentDrawer/componentDrawer";

interface UserDrawerProps {
  userName: string;
  userData: any;
}

function UserDrawer({ userName, userData }: UserDrawerProps) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <div
        className="w-fit cursor-pointer text-[#0494B4] text-base font-medium"
        style={{
          maxWidth: "160px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {userName}
      </div>
      <ComponentDrawer
        open={open}
        close={() => {
          setOpen(false);
        }}
        anchor="right"
        children={null}
        userName={userName}
        userData={userData}
        width={"75%"}
        closeOnBackdropClick={true}
      />
    </div>
  );
}

export default UserDrawer;
