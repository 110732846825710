import {useEffect, useState} from "react";
import { Box} from "@mui/material";
import {
  Text14pxMedium,
} from "../Shared/CommonComponents";
import styled from "@emotion/styled";
import TrestleButton from "../Shared/TrestleButton";
import { CheckBoxComponent } from "../Shared/ToggleComponents";
import { ActionButtonContainer } from "../Shared/PaymentWallet/WalletComponets/Styles";
import { AppConstants } from "../../constants/AppConstants";
import { useNavigate } from "react-router-dom";
import { CreateManageSubscriptionSession, GetWalletData, UpdatemonthlyTransactionLimit } from "../Shared/PaymentWallet/Services/PaymentWalletServices";
import { ConstantTexts } from "../../constants/ConstantTexts";
import { useAppDispatch } from "../../app/hooks";
import { setToast } from "../../app/toastReducer";

interface BillingDetailsTabProps {
  setDialogOpen: any;
  setLoading: any;
  userEmail: any;
}

const BillingDetailsTab = ({
    setDialogOpen,
    setLoading,
    userEmail,
}: BillingDetailsTabProps) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [walletData, setWalletData] = useState<any>(null);
  const [isMaxSpendLimitEnabled, setIsMaxSpendLimitEnabled] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    const fetchWalletData = async () => {
      const data = await GetWalletData(userEmail.replace("+", "%2b"));
      setWalletData(data);
      setIsMaxSpendLimitEnabled(data.data.monthlyTransactionLimit === AppConstants.MAX_MONTHLY_SPEND_LIMIT);
      setIsLoading(false);
    };

    fetchWalletData();
  }, []);

  const manageSubscriptionApiCall = async () => {
      setLoading(true);
      const response = await CreateManageSubscriptionSession(userEmail);
      const { statusCode = 401, data = {} } = response;
      if (statusCode === 200) {
        window.open(data, "_self");
      }
    };

  const handleMaxSpendLimitChange = () => {
      setIsMaxSpendLimitEnabled((prev) => !prev);
      setIsEditing(true);
  };
  
  const handleCancel = () => {
      console.log(walletData.data.monthlyTransactionLimit===AppConstants.MAX_MONTHLY_SPEND_LIMIT);
      setIsMaxSpendLimitEnabled(walletData.data.monthlyTransactionLimit === AppConstants.MAX_MONTHLY_SPEND_LIMIT);
      setIsEditing(false);
  };
  
  const handleSave = async () => {
      setIsSaving(true);
      const newLimit = isMaxSpendLimitEnabled ? AppConstants.MAX_MONTHLY_SPEND_LIMIT : AppConstants.DEFAULT_MONTHLY_SPEND_LIMIT;
      try {
        const response = await UpdatemonthlyTransactionLimit(userEmail, newLimit.toString());
        if (response.statusCode === 200) {
          setWalletData((prevState: any) => ({
            ...prevState,
            data: {
              ...prevState.data,
              monthlyTransactionLimit: newLimit
            }
          }));
          setIsMaxSpendLimitEnabled(newLimit === AppConstants.MAX_MONTHLY_SPEND_LIMIT);
          setIsEditing(false);
          dispatch(
            setToast({
              open: true,
              severity: AppConstants.TOAST_SUCCESS,
              msg: ConstantTexts.SPEND_LIMIT_UPDATED,
            })
          );
        } else {
          dispatch(
            setToast({
              open: true,
              severity: AppConstants.TOAST_ERROR,
              msg: ConstantTexts.SPEND_LIMIT_UPDATE_ERROR,
            })
          );
        }
      } catch (error) {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: ConstantTexts.SPEND_LIMIT_UPDATE_ERROR,
          })
        );
      }
      setIsSaving(false);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      {walletData.data.walletDisplayType === "SELF_SERVE" ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <PlanDetailsCardContainer>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Text14pxMedium>
                {`Wallet balance: ${
                    walletData.data.balance !== undefined && walletData.data.balance !== null
                      ? parseFloat(walletData.data.balance) < 0
                        ? `-$${Math.abs(parseFloat(walletData.data.balance)).toFixed(2)}`
                        : `$${parseFloat(walletData.data.balance).toFixed(2)}`
                      : '0.00'
                  }`}
              </Text14pxMedium>
              <Text14pxMedium>
                {walletData.data.autoRechargeEnabled
                  ? "Automatic wallet refill enabled"
                  : "Automatic wallet refill disabled"}
              </Text14pxMedium>
            </Box>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <ActionButtonContainer
                u={"underline"}
                onClick={manageSubscriptionApiCall}
              >
                Stripe Billing Details
              </ActionButtonContainer>
            </Box>
          </PlanDetailsCardContainer>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className="flex items-center">
              <CheckBoxComponent
                value={isMaxSpendLimitEnabled}
                toggleCheck={handleMaxSpendLimitChange}
              />
              <span className="text-[15px] font-normal text-[#163545] leading-10 ml-2 mt-auto">
                Maximum Spend limit
              </span>
            </div>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <PlanDetailsCardContainer>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Text14pxMedium>No active self-serve plan.</Text14pxMedium>
            </Box>
          </PlanDetailsCardContainer>
        </Box>
      )}
      {isEditing && !isSaving && (
        <SaveChangesBtnPopup className="w-full p-10 bg-white">
          <div className="flex">
            <TrestleButton
              onClick={handleCancel}
              label="Cancel and Revert Changes"
              type="secondry"
              className="flex-1 !max-w-[250px]"
            />
            <TrestleButton
              onClick={handleSave}
              label="Save Changes"
              type="primary"
              className="!w-[250px] ml-auto"
            />
          </div>
        </SaveChangesBtnPopup>
      )}
    </>
  );
};

export const PlanDetailsCardContainer = styled.div`
  width: 100%;
  height: 70px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
`;

export const SaveChangesBtnPopup = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  margin-left: -40px;
  width: 75%;
  box-shadow: 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
`;

export default BillingDetailsTab;
