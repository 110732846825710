import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./app/store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

Sentry.init({
  dsn: "https://fdb7c23f900aae42b7bb393c1709a937@o4507210857250816.ingest.us.sentry.io/4507294477451264",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation: useLocation,
      useNavigationType: useNavigationType,
      createRoutesFromChildren: createRoutesFromChildren,
      matchRoutes: matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [`${process.env.REACT_APP_SENTRY_ALLOWED_HOST}*`],

  beforeSend(event, hint) {
    // Check the event level and ignore if it's warning or info
    console.log("event.level", event, event.level);
    if (event.level === "error") {
      return event; // Discard the event
    }

    if (event.message && event.message.toLowerCase().includes("warning")) {
      // Discard the event if it's a warning elevated to error level
      return null;
    }

    // Default behavior: Send all other events to Sentry
    return null;
  },

  ignoreErrors: [
    // Ignore messages containing 'warning' or 'info' (case-insensitive)
    "Warning",
    "Info",
    /warning/i,
    /info/i,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: `${process.env.REACT_APP_SENTRY_ENVIRONMENT}`,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();
