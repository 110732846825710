import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { BrandingPage } from "../../Shared/BrandingPage";
import {
  MainHeaderText,
  BrandingPageChildContainer,
} from "../../Shared/CommonComponents";
import { InputErrortext } from "../../Shared/InputTextField";
import TrestleButton from "../../Shared/TrestleButton";
import { ForwardArrow, ResendIcon } from "../../../assets/svgs";
import { RouteConstants } from "../../../constants/RouteConstants";
import { AppConstants } from "../../../constants/AppConstants";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import { OTPInputField } from "../../Shared/OTPInputField";
import Auth from "@aws-amplify/auth";
import { setToast } from "../../../app/toastReducer";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { verifyEmailBackend } from "../../../http/authentication-service";
import Alerttriangle from "../../../assets/Alert-Triangle.svg";

export const VerifyOTP = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const token: any = useAppSelector((state: any) => state.authReducer.token);

  const [otp, setOtp] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [otpError, setOtpError] = useState<string>("");

  const userData = JSON.parse(sessionStorage.getItem("signup-data") || "{}");

  const VerifyEmailWithCode = async () => {
    const userData = JSON.parse(sessionStorage.getItem("signup-data") || "{}");
    let payload = {
      username: userData?.cognitoId || "",
      code: otp,
    };
    try {
      const response = await Auth.confirmSignUp(userData.email, payload.code);
      if (response === "SUCCESS") {
        const res = await verifyEmailBackend(userData.email);
        const { status = "", data = null } = res || {};
        if (status === HTTP_STATUS.HTTP_OK) {
          dispatch(
            setToast({
              open: true,
              severity: "success",
              msg: AppConstants.EMAIL_VERIFICATION_MSG,
            })
          );
          sessionStorage.clear();
          navigate(RouteConstants.ROUTE_LOGIN);
        } else {
          dispatch(
            setToast({
              open: true,
              severity: AppConstants.TOAST_ERROR,
              msg: data.details,
            })
          );
        }
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setOtpError(AppConstants.INVALID_VERIFICATION_CODE);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: error.message,
        })
      );
      console.log("error: ", error.message);
    }
  };

  const ResendCode = async () => {
    try {
      const response = await Auth.resendSignUp(userData.email || "");
      dispatch(
        setToast({
          open: true,
          severity: "success",
          msg: AppConstants.RESEND_VERIFICATION_SUCCESS,
        })
      );
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: error.message,
        })
      );
      console.log("error: ", error.message);
    }
  };

  return (
    <>
      <BrandingPage loader={isLoading}>
        <BrandingPageChildContainer className="!text-center !w-fit !px-[10px] lg:!px-[90px]">
          <MainHeaderText className="!text-center">
            {ConstantTexts.VERIFY_OTP_HEADER}
          </MainHeaderText>
          <div className="my-[15px] sm:mt-[30px] sm:mb-[80px] mx-auto text-base font-normal text-[#163545] max-w-[550px]">
            {ConstantTexts.VERIFY_OTP_INFO_TEXT.replace(
              "<email>",
              userData?.email || "example@example.com"
            )}
          </div>

          <div
            className={`mt-8 sm:mt-20 ${
              otpError ? "mb-[20px] sm:mb-[45px]" : "mb-8 sm:mb-[20]"
            } sm:w-fit sm:mx-auto`}
          >
            <OTPInputField
              otp={otp}
              setOtp={(val: string) => {
                setOtp(val);
                setOtpError("");
              }}
              error={otpError}
            />
            {otpError && (
              <InputErrortext className="flex text-center mt-5 mx-auto w-fit">
                <img src={Alerttriangle} alt="required" className="mr-[10px]" />
                {otpError}
              </InputErrortext>
            )}
          </div>

          <div className="text-center w-[100%]">
            <div>
              <TrestleButton
                onClick={() => {
                  setIsLoading(true);
                  VerifyEmailWithCode();
                }}
                label="Continue to Sign In"
                disabled={otp.length !== 6}
                type="primary"
                icon={ForwardArrow}
              />
            </div>

            <div className="mt-5">
              <TrestleButton
                onClick={() => {
                  setIsLoading(true);
                  ResendCode();
                }}
                label="Resend Verification Email"
                type="secondry"
                icon={ResendIcon}
                iconFirst={true}
              />
            </div>
          </div>

          <p className="text-base font-normal text-[#163545] mt-5 sm:mt-[10] text-center">
            {/* In case of wrong information{" "} */}
            <u
              className="text-[#0494B4] font-bold cursor-pointer"
              onClick={() => {
                navigate(RouteConstants.ROUTE_SIGNUP);
              }}
            >
              Return to Sign Up
            </u>
          </p>
        </BrandingPageChildContainer>
      </BrandingPage>
    </>
  );
};
