import { useState, useEffect } from "react";
import Accordions from "../../Shared/Accordions/Accordions";
import {
  APIKEY_LABEL,
  ComponentLabelHeader,
  OldPortalDiv,
  Text14pxMedium,
  Text16pxBold,
  TextLabel,
  TextValue,
} from "../../Shared/CommonComponents";
import InputTextField, { InputErrortext } from "../../Shared/InputTextField";
import TrestleTable, {
  TableHeaderProps,
} from "../../Shared/TrestleTable/TrestleTable";
import TrestleButton from "../../Shared/TrestleButton";
import LoaderComponent from "../../Shared/LoaderComponent";
import {
  getSubscriptions,
  requestProductAccess,
  getRevokedApiKeys,
} from "../../../http/authentication-service";
import { changeSubscriptionName } from "../../../http/subscription-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import { useDispatch } from "react-redux";
import ProductIcon from "../../../assets/product_icon.svg";
import NoDataFound from "../../Shared/NoDataFound";
import { useAppSelector } from "../../../app/hooks";
import RevokeAPIKey from "../../RevokeApiKey/RevokeApiKey";
import moment from "moment";
import { Box, Tooltip } from "@mui/material";
import {
  CheckIcon,
  DeleteItemCross,
  ForwardArrow,
  InfoIcon,
  PencilIcon,
  TrestleIcon,
} from "../../../assets/svgs";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import WaringPopup from "../../Shared/WarningPopup";
import DropDown from "../../Shared/DropDown";
import {
  getAllProducts,
  uploadCsvFile,
} from "../../../http/subscription-service";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import Alerttriangle from "../../../assets/Alert-Triangle.svg";
import { isEmpty } from "lodash";
import TestRequestComponent from "../../Shared/TestRequestComponent";
import TestRequestForm from "../../Shared/TestRequestFormComponent";
import { RouteConstants } from "../../../constants/RouteConstants";
import { useNavigate } from "react-router-dom";
import { setWallet } from "../../../app/walletReducer";
import { GetWalletData } from "../../Shared/PaymentWallet/Services/PaymentWalletServices";
import { getTimeDifference } from "../../../utils/timeDifference";
import { Helmet } from "react-helmet";

function RequestAccessComponent({ data, RequestAccess }: any) {
  return (
    <div className="flex">
      <TextValue
        className="underline text-[#0494B4] cursor-pointer"
        onClick={() => {
          RequestAccess(data);
        }}
      >
        {`Request Access`}
      </TextValue>
    </div>
  );
}

function YourApiKey() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<string | false>("");
  const [ApiKeyList, setApiKeyList] = useState<any[]>([]);
  const [revokedApiKeyList, setRevokedApiKeyList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [openPopup, setOpenPopup] = useState<string>("");
  const [productOptions, setProductOptions] = useState<any>({});
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [CSVFileError, setCSVFileError] = useState<string>("");

  const [editKeyName, setEditKeyName] = useState<boolean>(false);
  const [newKeyName, setNewKeyName] = useState<any>({
    subscriptionId: "",
    keyName: "",
  });

  const [currentEle, setCurrentEle] = useState<any>({});
  const [disableDropDown, setDisableDropDown] = useState<boolean>(false);

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const walletData: any = useAppSelector(
    (state: any) => state.walletReducer.walletData
  );

  const PRODUCTS_REQUIRING_VERIFICATION = [
    "Reverse Address API",
    "Reverse Phone API",
    "Smart CNAM API",
    "Caller Identification API",
    "Find Person API",
  ];
  const [hasProductRequiringVerification, setHasProductRequiringVerification] =
    useState(false);

  const enterpriseUser = walletData.walletDisplayType === "UNLIMITED";

  useEffect(() => {
    const hasVerificationProduct = ApiKeyList.some((ele) => {
      const hasProduct = ele.products.some((product: { productName: string }) =>
        PRODUCTS_REQUIRING_VERIFICATION.includes(product.productName)
      );
      return !enterpriseUser && hasProduct;
    });

    setHasProductRequiringVerification(hasVerificationProduct);
  }, [ApiKeyList, enterpriseUser]);

  const uploadCSVFile = async (payload: any) => {
    setLoaderText("Uploading...!");
    setIsLoading(true);
    const response = await uploadCsvFile(payload);
    const { status = 400, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg: (
            <div>
              Done! {selectedFile.name} uploaded.
              <br />
              Once your file is processed, results will be emailed to you at{" "}
              {userData.email}
            </div>
          ),
        })
      );

      setSelectedFile(null);
      setSelectedProduct("");
      setOpenPopup("");
      setLoaderText("");
      setIsLoading(false);
    } else {
      setLoaderText("");
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const updateSubscriptionName = async (id: any, name: string) => {
    setIsLoading(true);
    const response = await changeSubscriptionName(id, name);
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      // setIsLoading(false);
      await fetchApiKeyList().then(() => {
        setIsLoading(false);
      });
      setEditKeyName(false);
      setNewKeyName({
        subscriptionId: "",
        keyName: "",
      });
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg: "Subscription name updated successfully",
        })
      );
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const fetchProducts = async () => {
    setIsLoading(true);
    const response = await getAllProducts();
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      // setIsLoading(false);
      let temp: any = {};
      Object.keys(data.data).forEach((key: string) => {
        return (temp[data.data[key]] = key);
      });
      setProductOptions(temp);
      let d: any = Object.keys(temp).sort();
      // setSelectedProduct(d.length ? d[0] : "");
    } else {
      // setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const fetchApiKeyList = async () => {
    setIsLoading(true);
    const response = await getSubscriptions();
    const { status = 400, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setApiKeyList(data.data);
    } else {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const fetchRevokedApiKeyList = async () => {
    setIsLoading(true);
    const response = await getRevokedApiKeys();
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setRevokedApiKeyList(data.data);
      // setIsLoading(false);
    } else {
      // setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const RequestAccess = async (productData: any) => {
    setIsLoading(true);
    const { subscriptionId, usagePlanId } = productData;

    let payload = {
      subscriptionId,
      usagePlanId,
    };

    const response = await requestProductAccess(payload);
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK || status === HTTP_STATUS.HTTP_CREATED) {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          heading: "Access request sent!",
          msg: "You should receive access in the next few hours.",
          tostIcon: CheckIcon,
        })
      );
      await fetchApiKeyList().then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const ActionItemRender = (item: any) => {
    const { status, expired, limit, usageCountV2 } = item;
    
    const getRemainingQueries = () => {
      if (limit === 0 || status != AppConstants.STATUS_AVALIABLE) return null;
      const remaining = Math.max(0, limit - usageCountV2);
      return `${remaining}/${limit} queries remaining`;
    };

    const renderStatus = () => {
      if (expired === "EXPIRED") {
        return (
          <span className="text-base" style={{ cursor: "default" }}>
            API Key Expired
          </span>
        );
      } else if (status === AppConstants.STATUS_AVALIABLE) {
        return (
          <span className="text-base" style={{ cursor: "default" }}>
            Available
          </span>
        );
      } else if (status === AppConstants.STATUS_REQUESTED) {
        return (
          <span className="text-base" style={{ cursor: "default" }}>
            Pending Approval
          </span>
        );
      } else if (status === AppConstants.STATUS_DENIED) {
        return (
          <span
            className="text-base text-[#B23352] font-bold"
            style={{ cursor: "default" }}
          >
            Access Denied
          </span>
        );
      } else if (
        status === AppConstants.STATUS_UNAVALIABLE ||
        status === AppConstants.STATUS_REMOVE ||
        status === AppConstants.STATUS_NOT_IN_USE
      ) {
        return (
          <span className="text-base" style={{ cursor: "default" }}>
            Unavailable
          </span>
        );
      } else if (status === AppConstants.STATUS_CAN_REQUEST) {
        return (
          <RequestAccessComponent data={item} RequestAccess={RequestAccess} />
        );
      }
    };

    const remainingQueries = getRemainingQueries();

    return (
      <div className="flex flex-col">
        {renderStatus()}
        {remainingQueries && (
          <span className="text-sm text-gray-500 mt-1">{remainingQueries}</span>
        )}
      </div>
    );
  };

  const handleTestRequestClicked = (ele: any, displayText?: string) => {
    if (displayText) {
      handleMixPanel(MixPanelEvents.TEST_REQUEST, {
        type: displayText,
        productName: ele?.apiName,
      });
    }
    if (ele.usagePlanId === currentEle?.usagePlanId) {
      setCurrentEle({});
    } else {
      setCurrentEle(ele);
    }
  };

  const GetTestRequestComponent = (ele: any) => {
    if (
      ele.productName === "ID Lookup API" ||
      ele.productName === "Phone Feedback API" ||
      ele.status === "DENIED"
    ) {
      return;
    } else {
      return (
        <TestRequestComponent
          status={ele.status}
          rowEle={ele}
          currentEle={currentEle}
          handleClick={(displayText?: string) =>
            handleTestRequestClicked(ele, displayText)
          }
          disableDropDown={disableDropDown}
          setDisableDropDown={setDisableDropDown}
        />
      );
    }
  };

  const getHeadCells1 = (
    limit: number,
    lastUsageUpdatedDate: any,
    planType: string
  ) => {
    return [
      {
        id: "productName",
        numeric: false,
        disablePadding: false,
        label: "Product Name",
        width: "30%",
        isCustomElements: true,
        customElements: (ele: any) => {
          const needsVerification = PRODUCTS_REQUIRING_VERIFICATION.includes(
            ele.productName
          );
          return (
            <div className="flex" style={{ cursor: "default" }}>
              <img src={ProductIcon} alt="product icon" />
              <div className="font-bold text-base text-[#163545] ml-3">
                {ele.productName}
                {!enterpriseUser && needsVerification && (
                  <span className="text-orange-500 ml-1">*</span>
                )}
              </div>
            </div>
          );
        },
        sortable: true,
      },
      {
        id: "status",
        numeric: true,
        disablePadding: false,
        label: "Access",
        isCustomElements: true,
        width: "20%",
        customElements: (ele: any) => ActionItemRender({
          ...ele,
          limit: limit,
          usageCountV2: ele.usageCountV2
        }),
        sortable: false,
      },
      {
        id: "testRequest",
        numeric: true,
        disablePadding: false,
        label: "",
        isCustomElements: true,
        width: "25%",
        customElements: (ele: any) => GetTestRequestComponent(ele),
        sortable: false,
      },
      {
        id: "usageCountV2",
        numeric: true,
        disablePadding: false,
        label: (
          <div className="flex items-center">
            <div>
              This Month's Usage
              <Text14pxMedium c="#999999">
                Last updated {getTimeDifference(lastUsageUpdatedDate)} ago
              </Text14pxMedium>
            </div>
            {/* <Tooltip
              title={ConstantTexts.USAGE_TABLE_HEADER_INFO}
              arrow
              sx={{ width: "100px" }}
            >
              <span className="ml-[5px]">{InfoIcon}</span>
            </Tooltip> */}
          </div>
        ),
        isCustomElements: false,
        width: "25%",
        customElements: (ele: any) => {},
        sortable: true,
        className: "!text-right !pr-[45px]",
        headClassName: "!text-right !pr-[20px]",
      },
    ];
  };

  const expiryDate = (date_string: string, creationDate: string) => {
    let expiration = moment(date_string).format("YYYY-MM-DD");
    let current_date = moment(new Date().toUTCString()).format("YYYY-MM-DD");
    let days: any = 0;
    if (expiration < current_date) {
      days = 0;
    } else {
      days = Number.isNaN(moment(expiration).diff(current_date, "days"))
        ? 0
        : moment(expiration).diff(current_date, "days");
    }
    return days;
  };

  const [loaderText, setLoaderText] = useState<any>("");

  const init = async () => {
    await fetchApiKeyList();
    await fetchRevokedApiKeyList();
    await fetchProducts();

    setExpanded(`panel${0}`);
    if (!isEmpty(userData)) {
      handleMixPanel(MixPanelEvents.VISIT_YOURAPIKEY_PAGE, {
        userId: userData?.id,
        userEmail: userData?.email,
      });
    }
  };

  const GetWalletDataAPICall = async () => {
    setIsLoading(true);
    const PaymentSuccess = new URLSearchParams(window.location.search).get(
      "paymentSuccess"
    );
    let count = 0;
    const myInterval = setInterval(async () => {
      const response = await GetWalletData();
      const { statusCode = 401, data = [] } = response;
      if (statusCode === 200) {
        if (count === 14) {
          localStorage.setItem("paymentSuccess", "false");
          // setWalletData(data);
          dispatch(setWallet(data));
          init().then(() => {
            setIsLoading(false);
          });
          clearInterval(myInterval);
          dispatch(
            setToast({
              open: true,
              severity: AppConstants.TOAST_ERROR,
              msg: "Plan upgradation Failed Please contact support",
            })
          );
          count = 0;
        } else {
          count += 2;
        }
        if (PaymentSuccess === ("true" || true)) {
          console.log("GetWalletData: PaymentSuccess", PaymentSuccess);
          if (response.data.walletDisplayType === "SELF_SERVE") {
            localStorage.setItem("paymentSuccess", "false");
            // setWalletData(data);
            dispatch(setWallet(data));
            init().then(() => {
              setIsLoading(false);
            });
            clearInterval(myInterval);
          }
        } else {
          localStorage.setItem("paymentSuccess", "false");
          // setWalletData(data);
          dispatch(setWallet(data));
          init().then(() => {
            setIsLoading(false);
          });
          clearInterval(myInterval);
        }
      } else {
        //console.log(response);
        localStorage.setItem("paymentSuccess", "false");
        setIsLoading(false);
        clearInterval(myInterval);
      }
    }, 2000);
  };

  useEffect(() => {
    window.SetMode("USER");
    GetWalletDataAPICall();
  }, []);

  const UsageDisplay = ({
    label,
    value,
    hasVerification,
  }: {
    label: string;
    value: number;
    hasVerification: boolean;
  }) => (
    <div className="flex w-[100%] pr-[45px] justify-between items-center text-base font-medium text-[#163545]">
      <div className="flex items-center">
        {hasVerification && (
          <div className="text-base font-medium text-[#163545]">
            <span className="text-orange-500">*</span> Certain APIs require
            additional verification to use at scale. &nbsp;
            <a
              href="https://trestleiq.com/contact/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#0494B4] hover:underline"
            >
              Contact us
            </a>
            &nbsp;or{" "}
            <a
              href="https://meetings.hubspot.com/ana-trudeau/developer-portal-general-meeting?uuid=5e8905ed-3bb5-4a03-ae32-b513ebef8ef3"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#0494B4] hover:underline"
            >
              book a meeting
            </a>{" "}
            to inquire.
          </div>
        )}
      </div>
      <div className="flex items-center">
        <div className="text-right pr-[40px]">{label}:</div>
        <div className="text-center">{value}</div>
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trestle Developer Portal</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DEV_PORTAL_URL}api-keys`}
        />
      </Helmet>
      <LoaderComponent loader={isLoading} msg={loaderText} />
      {/* {true && (
        <div className="-mt-[45px]">
          <OldPortalDiv className="!z-[999]">
            <img
              src={ProductIcon}
              alt="product icon"
              className="h-9 my-auto mr-3 ml-[50px]"
            />

            <div className="mr-auto overflow-hidden whitespace-nowrap text-ellipsis">
              <span className="text-base font-bold text-[#163545]">
                Looking to test our coverage and accuracy at scale?
              </span>{" "}
              <span className="text-base text-[#555454]">
                Upload a CSV file, and we'll email the results to you once
                processed.
              </span>
            </div>

            <div className="ml-[100px] w-[200px]">
              <TrestleButton
                onClick={() => {
                  setCSVFileError("");
                  setOpenPopup("RUN_TEST");
                }}
                label="Run My Test"
                type="primary"
                icon={ForwardArrow}
                className="!h-[40px] !leading-[40px] !p-0"
              />
            </div>
            <div
              className="ml-4 mr-[100px] text-[#0494B4] font-bold cursor-pointer min-w-fit"
              onClick={() => {
                setOpenPopup("LEARN_MORE");
              }}
            >
              <u>Learn More</u>
            </div>
          </OldPortalDiv>
        </div>
      )} */}
      <div className="pr-[100px] pt-[50px]">
        <div className="flex pl-[50px] mb-[30px]">
          <ComponentLabelHeader className="flex-1">
            Hi, {userData?.firstName || "User"}
          </ComponentLabelHeader>
          <ComponentLabelHeader className="!text-[24px] !font-[400]">
            Welcome to Trestle
          </ComponentLabelHeader>
        </div>

        <div>
          {ApiKeyList.map((ele: any, index: number) => {
            const {
              apiKeyName = "",
              lastUsageUpdatedDate = "",
              apiKey = "",
              planType = "",
              qps = "",
              limit = "",
              products = [],
              status = "",
              apiKeyEnabled = false,
            } = ele || {};

            let totalUsage: number = 0;
            products.forEach((product: any) => {
              totalUsage += product.usageCount;
            });
            // VERSION 2
            let totalUsageV2: number = 0;
            products.forEach((product: any) => {
              totalUsageV2 += product.usageCountV2;
            });
            let remainingUsage: number =
              limit === 0 ? -1 : limit - totalUsageV2;
            let expiryData: any = expiryDate(ele.expiryDate, ele.creationDate);
            let isEnterprise: any = planType === "ENTERPRISE";

            let subscriptionError: any =
              status === "EXPIRED" ? "This API key has expired." : "";

            const panel = `panel${index}`;

            return (
              <Accordions
                key={`key-accor-${ele}`}
                expanded={expanded}
                setExpanded={(val: any) => {
                  if (expanded !== panel) {
                    setExpanded(panel);
                    setNewKeyName({
                      subscriptionId: "",
                      keyName: "",
                    });
                  } else if (val === "close") {
                    setExpanded(val);
                    setNewKeyName({
                      subscriptionId: "",
                      keyName: "",
                    });
                  }
                }}
                index={index}
                disabled={null}
                summary={
                  <div className="flex w-full items-center">
                    <APIKEY_LABEL className="max-w-[400px] w-full">
                      {expanded === `panel${index}` &&
                      newKeyName.subscriptionId === ele.subscriptionId ? (
                        <div className="flex">
                          <div className="w-full">
                            <InputTextField
                              name={"keyName"}
                              label={""}
                              value={newKeyName}
                              error={{}}
                              type={"text"}
                              onChange={(e: any) => {
                                setNewKeyName({
                                  ...newKeyName,
                                  keyName: e.target.value,
                                });
                              }}
                              disabled={
                                !(
                                  newKeyName.subscriptionId ===
                                  ele.subscriptionId
                                )
                              }
                              copyToClipboard={false}
                              height={"40px"}
                              maxLength={AppConstants.MAX_APIKEY_NAME_LENGTH}
                            />
                          </div>
                          <div className="px-3">
                            {!editKeyName ? (
                              <div className="mt-[5px]">{PencilIcon}</div>
                            ) : (
                              newKeyName.keyName.trim() &&
                              newKeyName.keyName !== apiKeyName && (
                                <TrestleButton
                                  onClick={() => {
                                    updateSubscriptionName(
                                      newKeyName.subscriptionId,
                                      newKeyName.keyName
                                    );
                                  }}
                                  label="Save"
                                  type="secondry"
                                  className="!h-[40px] !leading-[40px] !p-0 !px-5 !min-w-fit"
                                />
                              )
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="flex">
                          {apiKeyName || `Your Trial API Key {index + 1}`}
                          {expanded === `panel${index}` && (
                            <Tooltip title="Edit API Key name">
                              <div
                                className="mt-[6px] ml-1 cursor-pointer"
                                onClick={() => {
                                  setEditKeyName(true);
                                  setNewKeyName({
                                    subscriptionId: ele.subscriptionId,
                                    keyName: apiKeyName,
                                  });
                                }}
                              >
                                {PencilIcon}
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </APIKEY_LABEL>
                    <div
                      className="flex-1"
                      onClick={() => {
                        if (expanded !== panel) {
                          setExpanded(`close`);
                          setNewKeyName({
                            subscriptionId: "",
                            keyName: "",
                          });
                        }
                      }}
                    />
                    <div
                      className="flex items-center w-auto text-right"
                      key={"plan"}
                      onClick={() => {
                        setExpanded(`close`);
                        setNewKeyName({
                          subscriptionId: "",
                          keyName: "",
                        });
                      }}
                    >
                      <TextLabel className="mr-2">Subscription Plan:</TextLabel>
                      <TextValue>
                        {planType
                          .toLowerCase()
                          .replace(/_/g, "-")
                          .replace(/^\w/, (c: string) => c.toUpperCase())}
                      </TextValue>
                    </div>
                    {planType != "TRIAL" && (
                      <div
                        className="flex items-center w-auto text-right ml-8"
                        key={"quota"}
                        onClick={() => {
                          setExpanded(`close`);
                          setNewKeyName({
                            subscriptionId: "",
                            keyName: "",
                          });
                        }}
                      >
                        <TextLabel className="mr-2">Quota:</TextLabel>
                        <TextValue>
                          {limit == null || limit === 0
                            ? "Unlimited"
                            : typeof limit === "string"
                            ? `${limit
                                .toLowerCase()
                                .replace(/_/g, "-")
                                .replace(/^\w/, (c: string) => c.toUpperCase())} Queries /Month`
                            : `${String(limit)} Queries /Month`}
                        </TextValue>
                      </div>
                    )}
                  </div>
                }
                details={
                  <div className="w-full pb-[70px]">
                    {walletData?.walletDisplayType === "TRIAL" ||
                      (walletData?.walletDisplayType === "TRIAL_EXPIRED" && (
                        <div className="w-[100%] bg-[#E5F4F7] items-center flex my-[30px] h-[80px] px-[20px] justify-between">
                          <div className="flex gap-[20px] items-center">
                            {TrestleIcon}
                            {expiryData <= 0 ? (
                              <div>
                                <span className="text-[#00485A] font-bold">
                                  {" "}
                                  Your trial has ended.
                                </span>{" "}
                                Upgrade now to regain access and receive more
                                test queries.
                              </div>
                            ) : (
                              <div>
                                <span className="text-[#00485A] font-bold">
                                  {" "}
                                  Your trial is ending soon.
                                </span>{" "}
                                You have {expiryData} days remaining on Trial
                              </div>
                            )}
                          </div>

                          <TrestleButton
                            onClick={() => {
                              navigate(RouteConstants.ROUTE_PRICING_PAGE);
                              handleMixPanel(
                                MixPanelEvents.WALLET_UPGRADE_NOW_BANNER,
                                { userEmail: userData?.email }
                              );
                            }}
                            label="Upgrade Now"
                            type="primary"
                            className="!w-fit !h-[50px]"
                          />
                        </div>
                      ))}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div className="flex" style={{ alignItems: "center" }}>
                        <Text16pxBold
                          style={{
                            width: "10%",
                            height: "60px",
                            display: "flex",
                            backgroundColor: "#E5E5E5",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "default",
                          }}
                        >
                          API Key
                        </Text16pxBold>
                        <InputTextField
                          name={"apikey"}
                          label={""}
                          value={{ apikey: apiKey }}
                          error={{ apikey: subscriptionError ? " " : "" }}
                          type={"text"}
                          onChange={() => {}}
                          disabled={true}
                          copyToClipboard={true}
                        />
                      </div>
                      {!apiKeyEnabled && !subscriptionError && (
                        <div className="p-[8px] text-red-600">
                          Your Api Key Is Disabled
                        </div>
                      )}
                    </Box>

                    {subscriptionError && (
                      <InputErrortext className="flex mt-1">
                        <img
                          src={Alerttriangle}
                          alt="required"
                          style={{ marginRight: "4px" }}
                        />
                        {subscriptionError}
                      </InputErrortext>
                    )}

                    <div className={`${isEnterprise ? "mt-8" : ""}`}>
                      <TrestleTable
                        isPaginated={false}
                        childRow={(ele: any) => (
                          <TestRequestForm
                            rowEle={ele}
                            currentEle={currentEle}
                            RequestAccess={RequestAccess}
                            activeApiKey={apiKey}
                            setIsLoading={setIsLoading}
                            disableDropDown={disableDropDown}
                            setDisableDropDown={setDisableDropDown}
                          />
                        )}
                        headCells={getHeadCells1(
                          limit,
                          lastUsageUpdatedDate,
                          planType
                        )}
                        rows={
                          ele?.products.map((item: any) => {
                            return {
                              ...item,
                              subscriptionId: ele.subscriptionId,
                              expired: status,
                              quotaLimit: limit,
                              subscriptionStatus: apiKeyEnabled,
                            };
                          }) || []
                        }
                      />
                      <UsageDisplay
                        label={
                          planType === "SELF_SERVE" ? "Total" : "Queries Used"
                        }
                        value={totalUsageV2}
                        hasVerification={hasProductRequiringVerification}
                      />
                    </div>
                  </div>
                }
              />
            );
          })}

          {!isLoading && !ApiKeyList.length && !revokedApiKeyList.length && (
            <NoDataFound text={"There is no APIKEY"} className="mt-52" />
          )}

          <div>
            {revokedApiKeyList.length ? (
              <RevokeAPIKey listData={revokedApiKeyList} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <WaringPopup
        open={openPopup !== ""}
        close={() => {
          setSelectedFile(null);
          setSelectedProduct("");
          setOpenPopup("");
        }}
        title={
          openPopup === "LEARN_MORE"
            ? "Bulk File Testing"
            : "Select Product to Test"
        }
        text={""}
        htmlElement={
          openPopup === "LEARN_MORE" ? (
            <div>
              <div>
                Test our products up to 1,000 queries with a single file upload.
              </div>

              <div className="mt-4">
                If you'd like to test an even larger sample, please{" "}
                <span
                  className="text-[#0494B4] cursor-pointer"
                  onClick={() => {
                    window.open("https://trestleiq.com/contact/");
                  }}
                >
                  <u>contact us</u>
                </span>{" "}
                to help you set up your test.
              </div>
            </div>
          ) : (
            <>
              <div>
                Test our products up to 1,000 queries with a single file upload.
              </div>

              <div className="w-full mt-8">
                <DropDown
                  name={"apikeys"}
                  label={"Product"}
                  value={selectedProduct}
                  error={""}
                  placeholder={"Search"}
                  onChange={(val: any) => {
                    setSelectedProduct(val);
                    // let filePicker = document.getElementById("upload-csv-test");
                    // filePicker?.click();
                  }}
                  required={false}
                  options={Object.keys(productOptions).sort()}
                  className="!h-[50px] text-[#163545]"
                  multiSelect={false}
                  truncateOption={true}
                />

                {selectedProduct && !selectedFile && (
                  <div
                    className="text-sm bg-slate-100 py-1 px-2 rounded-sm w-fit cursor-pointer text-[#163545] mt-3 hover:bg-[#A2F0EC] hover:text-[#006D88]"
                    onClick={() => {
                      let filePicker =
                        document.getElementById("upload-csv-test");
                      filePicker?.click();
                    }}
                  >
                    Select CSV file
                  </div>
                )}

                {CSVFileError && (
                  <div className="text-xs text-red-500 my-2">
                    {CSVFileError}
                  </div>
                )}

                <div className="opacity-0 pointer-events-none">
                  <input
                    type="file"
                    name="upload-csv"
                    id="upload-csv-test"
                    multiple={false}
                    accept="text/csv"
                    onChange={(e: any) => {
                      const { files = [] } = e.target || {};
                      if (files.length) {
                        const [file = null] = files;
                        if (file && file.type === "text/csv") {
                          if (file.size >= 5242880) {
                            setCSVFileError("File size must be less than 5MB");
                          } else {
                            setSelectedFile(file);
                            setCSVFileError("");
                          }
                        } else {
                          setCSVFileError("Only CSV files are allowed");
                        }
                      }
                    }}
                  />
                </div>
                {selectedFile && (
                  <div className="bg-[#F3F5F6] px-3 py-2 rounded-sm flex">
                    <div>{selectedFile.name}</div>
                    <div
                      className="ml-auto hover:text-red-500 cursor-pointer"
                      onClick={() => {
                        setSelectedFile(null);
                      }}
                    >
                      {DeleteItemCross}
                    </div>
                  </div>
                )}
              </div>
            </>
          )
        }
        buttonLable={
          openPopup === "LEARN_MORE" ? "Run My Test" : "Upload CSV File"
        }
        cancelLabel="Go Back"
        buttonIcon={ForwardArrow}
        onAction={() => {
          if (openPopup === "LEARN_MORE") {
            setCSVFileError("");
            setOpenPopup("RUN_TEST");
          } else {
            const multiData = new FormData();
            multiData.append("file", selectedFile);
            multiData.append("productName", selectedProduct);

            const init = async () => {
              await uploadCSVFile(multiData);
            };

            init();
          }
        }}
        disabledBtn={
          openPopup === "LEARN_MORE" ? false : !selectedFile || !selectedProduct
        }
      />
    </>
  );
}

export default YourApiKey;
