import BaseAPIClient from "./api";
import { AppConstants } from "../constants/AppConstants";

const globalHeaders: any = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  
  const API_BASE_URL: string =
    process.env.REACT_APP_API_URL || "http://localhost";
  const API = BaseAPIClient?.axiosInstance;

export const getManageProductAccess = async (data : any) => {
    const headers = { ...globalHeaders };
    const uri: string = `${API_BASE_URL}${AppConstants.GET_MANAGE_PRODUCT_ACCESS}`;
    const response = await API.post(uri, data,{
      headers,
    });
    return response;
}


export const changeProductAccess = async (data : any) => {
  const headers = { ...globalHeaders };
  const uri: string = `${API_BASE_URL}${AppConstants.CHANGE_PRODUCT_ACCESS}`;
  const response = await API.put(uri, data,{
    headers,
  });
  return response;
}

export const getAllUsers = async (data:any) => {
  const headers = { ...globalHeaders };
  const uri: string = `${API_BASE_URL}${AppConstants.GET_ALL_USERS}`;
  const response = await API.post(uri,data,{
    headers,
  });
  return response;
}


export const getAllMaxioCustomerIDs = async (data:any) => {
  const headers = { ...globalHeaders };
  const uri: string = `${API_BASE_URL}${AppConstants.GET_ALL_MAXIO_CUSTOMERS}`;
  const response = await API.get(uri, {headers});
  return response;
}

export const getAllMaxioSubscriptionsForCustomer = async (customerId:string) => {
  const headers = { ...globalHeaders };
  const uri: string = `${API_BASE_URL}${AppConstants.GET_MAXIO_SUBSCRIPTIONS_FOR_CUSTOMER}?customerId=${customerId}`;
  const response = await API.get(uri, {headers});
  return response;
}

export const setMaxioCustomerID = async (maxioCustomerId: string, trestleUserId: string) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.SET_MAXIO_CUSTOMER_ID}?customerId=${maxioCustomerId}&userId=${trestleUserId}`;
  const response = await API.put(uri, {
    headers,
  });
  return response;
};

export const UpdateUserStatus = async (data : any) => {
  const headers = {...globalHeaders };
  const uri: string = `${API_BASE_URL}${AppConstants.UPDATE_USER_STATUS}`;
  const response = await API.put(uri, data,{
    headers,
  });
  return response;
}
