import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import toastReducer from "./toastReducer";
import * as Sentry from "@sentry/react";
import walletReducer from "./walletReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import loaderReducer from "./loaderReducer";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["authReducer", "toastReducer"], // Exclude walletReducer from persisting
};

const rootReducer = combineReducers({
  authReducer: authReducer,
  toastReducer: toastReducer,
  walletReducer: walletReducer,
  loaderReducer: loaderReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // enhancers: () =>
  //       getDefaultEnhancers().concat(sentryReduxEnhancer),
});

export default store;
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
