import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import LoaderComponent from "../../Shared/LoaderComponent";
import { ComponentLabelHeader } from "../../Shared/CommonComponents";
import DropDown from "../../Shared/DropDown";
import {
  callExportCsvAPIV2,
  getProductsAndAPIkeys,
  getUsageDataV2,
} from "../../../http/subscription-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import DateRangeTrestle from "../../Shared/DateRangePicker";
import TrestleButton from "../../Shared/TrestleButton";
import moment from "moment";
import { useAppSelector } from "../../../app/hooks";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const datasetsColors = [
  {
    borderColor: "#254b87",
    backgroundColor: "#254b87",
  },
  {
    borderColor: "#addc91",
    backgroundColor: "#addc91",
  },
  {
    borderColor: "#9daecc",
    backgroundColor: "#9daecc",
  },
  {
    borderColor: "#257675",
    backgroundColor: "#257675",
  },
  {
    borderColor: "#89b6b5",
    backgroundColor: "#89b6b5",
  },
  {
    borderColor: "#0072ce",
    backgroundColor: "#0072ce",
  },
  {
    borderColor: "#7eb7e8",
    backgroundColor: "#7eb7e8",
  },
  {
    borderColor: "#1fa040",
    backgroundColor: "#1fa040",
  },
];

interface YourUsageProps {
  userId?: string | number;
  userEmail?: string;
  usersDetails?: any;
  isAdmin?: boolean;
  setInitialFormdate?:any;
}

function YourUsageV2({ userId = 0, userEmail = "", usersDetails = {}, isAdmin = false,setInitialFormdate = () => {}}: YourUsageProps) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [plotFilterData, setPlotFilterData] = useState<any>({
    products: ["All Products"],
    apikeys: [],
    timeline: "Daily",
    timeframe: "Last 30 days",
  });

  const [backPlotFilterData, setBackPlotFilterData] = useState<any>({
    products: [],
    apikeys: [],
    timeline: "Daily",
    timeframe: "",
  });

  const [chartData, setChartData] = useState<any>({ labels: [], datasets: [] });
  const [productOptions, setProductOptions] = useState<any>({});
  const [APIkeyOptions, setAPIkeyOptions] = useState<any>({});

  const [dateRage, setDateRage] = useState<any>({
    startTime: new Date(),
    endTime: new Date(),
  });

  const [timeframeOptions, setTimeframeOptions] = useState<any>([
    "Last 7 days",
    "Last 30 days",
    "Custom range",
  ]);

  const numberDays: any = {
    "Last 7 days": 7,
    "Last 30 days": 30,
    "Custom range": null,
  };

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const userMode: any = useAppSelector(
    (state: any) => state.authReducer.userMode
  );

  const [flag, setFlag] = useState<boolean>(false);

  const formatLineChartData = (pdata: any) => {
    const { labels, datasets } = pdata;
    let valueArray = labels.map((ele: any) => 0);

    let reviseDataSet = datasets.map((item: any) => {
      const { data: xdata, label } = item;
      let temp = xdata.map((x: number, i: number) => {
        return x + valueArray[i];
      });

      valueArray = temp;

      return { ...item, data: temp };
    });

    return { labels, datasets: reviseDataSet };
  };

  useEffect(() => {
    // let ret = formatLineChartData(data);
    // setChartData(ret);
    setInitialFormdate()
    fetchProductAndAPIkeys();
    handleMixPanel(MixPanelEvents.VISIT_USER_USAGE_PAGE, {
      userId: userData?.id,
      userEmail: userData?.email,
    });
  }, []);

  const FetchUsageChartData = async () => {
    setIsLoading(true);
    const payload = getPayload();
    const response = await getUsageDataV2(payload);
    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setIsLoading(false);
      setChartData(formatChartData(data.data));
    } else {
      setIsLoading(false);

      dispatch(
        setToast({
          type: "error",
          message: data.message,
        })
      );
    }
  };

  const formatChartData = (data: any) => {
    const { labels, productUsageData } = data;

    let datasets: any = [];

    Object.keys(productUsageData).forEach((key: string, index: number) => {
      let temp = {
        label: key,
        data: productUsageData[key],
        ...datasetsColors[index],
      };
      datasets.push(temp);
    });

    // return formatLineChartData({ labels, datasets });
    return { labels, datasets };
  };

  const StoreAPIKeys = (data: any, setData: any) => {
    let tempMap: any = {};

    data.forEach((ele: any) => {
      Object.keys(ele).forEach((key: string) => {
        tempMap[ele[key]] = key;
      });
    });

    setData(tempMap);
  };

  const exportCSV = () => {
    setIsLoading(true);
    let payload = getPayload();

    callExportCsvAPIV2(payload)
      .then((response) => {
        setIsLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `trestle_api_key_per_product_usage_${Date.now()}.csv`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setIsLoading(false);
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: "Not able to download CSV file",
          })
        );
      });
  };

  const fetchProductAndAPIkeys = async () => {
    setIsLoading(true); let response
    if (userMode === "ADMIN") {
      response = await getProductsAndAPIkeys(userEmail);
    } else {
      response = await getProductsAndAPIkeys();
    }
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setIsLoading(false);
      StoreAPIKeys(data.data.apiKeys, setAPIkeyOptions);
      StoreAPIKeys(data.data.products, setProductOptions);
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const getPayload = () => {
    const { products, apikeys, timeframe, timeline } = plotFilterData;
    const { id = "" } = userData;

    let startEndData: any = LastNthDays(timeframe);

    let productArr = products.filter(
      (product: any) => product !== "All Products"
    );

    let payload = {
      userIds: [userId || id],
      productIds: productArr.map((ele: any) => {
        return productOptions[ele];
      }),
      apiKeyIds: apikeys.map((ele: any) => {
        return APIkeyOptions[ele];
      }),
      ...startEndData,
      usageType: timeline,
    };

    return payload;
  };

  useEffect(() => {
    let opt = Object.keys(APIkeyOptions);

    if (opt.length > 0) {
      setPlotFilterData({
        ...plotFilterData,
        apikeys: userId ? [...opt] : [opt[0]],
        products: ["All Products"],
      });
      setFlag(true);
      FetchUsageChartData();
    }
  }, [APIkeyOptions]);

  const getProductsList = (data: any) => {
    let temp = [];
    if (data.length > 1 && data.includes("All Products")) {
      data.forEach((ele: any) => {
        if (ele !== "All Products") {
          temp.push(ele);
        }
      });
    } else {
      temp = data.length === 0 ? ["All Products"] : data;
    }
    return temp;
  };

  const differenceInMonths = (date1: any, date2: any) => {
    const monthDiff = date1.getMonth() - date2.getMonth();
    const yearDiff = date1.getYear() - date2.getYear();

    return monthDiff + yearDiff * 12;
  };

  const LastNthDays = (timeframeO: string) => {
    let lastDays: number = numberDays[timeframeO];

    if (lastDays !== null) {
      let result = [];
      for (let i = 0; i < lastDays; i++) {
        let d = new Date();
        d.setDate(d.getDate() - i);
        result.push(d);
      }

      return {
        startTime: dateFormat(result[result.length - 1]),
        endTime: dateFormat(result[0]),
      };
    } else {
      const { startTime, endTime } = dateRage;

      return {
        startTime: dateFormat(startTime),
        endTime: dateFormat(endTime),
      };
    }
  };

  const dateFormat = (date: any) => {
    return moment(date).format("YYYY-MM-DD");
  };

  return (
    <>
      <LoaderComponent
        loader={isLoading}
        msg={ConstantTexts.LONG_TIME_LOADING_TEXT}
      />
      {
        <>
          {!userId && (
            <div className="pr-[100px]">
              <div className="flex pl-[50px] mb-[30px]">
                <ComponentLabelHeader className="flex-1">
                  Product Usage
                </ComponentLabelHeader>
              </div>
            </div>
          )}
          <div
            className={`bg-white min-h-full ${userId ? "w-full" : "p-[45px] w-[80%]"
              }`}
          >
            {(usersDetails?.entityStatus == "DEACTIVATED" && isAdmin) ?(
              <div className="w-[100%] p-[12px] bg-red-600 h-[50px] mb-[30px]">
              This User Is Deactivated and won't be able to log in.
            </div>
              ):(
                <></>
              )}
            <div className="flex w-full mb-[60px]">
              {!userId && (
                <>
                  <div className="max-w-[180px] w-full mr-2">
                    <DropDown
                      name={"products"}
                      placeholder={"All Products"}
                      label={"Product"}
                      value={getProductsList(plotFilterData["products"])}
                      error={""}
                      onChange={(val: any) => {
                        setPlotFilterData({
                          ...plotFilterData,
                          products: val,
                        });
                        setBackPlotFilterData({
                          ...backPlotFilterData,
                          products: [],
                        });
                      }}
                      required={false}
                      options={[
                        ...["All Products"],
                        ...(Object.keys(productOptions) || []),
                      ]}
                      className="!h-[50px] text-[#163545]"
                      multiSelect={true}
                    />
                  </div>

                  <div className="max-w-[180px] w-full mr-2">
                    <DropDown
                      name={"apikeys"}
                      label={"API Key"}
                      value={plotFilterData["apikeys"]}
                      error={""}
                      placeholder={"Search"}
                      onChange={(val: any) => {
                        setPlotFilterData({
                          ...plotFilterData,
                          apikeys: val,
                        });
                      }}
                      required={false}
                      options={Object.keys(APIkeyOptions) || []}
                      className="!h-[50px] text-[#163545]"
                      multiSelect={true}
                      truncateOption={true}
                    />
                  </div>
                </>
              )}
              <div className="max-w-[180px] w-full mr-2">
                <DropDown
                  name={"timeframe"}
                  label={"Timeframe"}
                  value={plotFilterData.timeframe}
                  error={""}
                  placeholder={"Search"}
                  onChange={(val: any) => {
                    const { timeframe, timeline } = plotFilterData;
                    setPlotFilterData({
                      ...plotFilterData,
                      timeframe: val,
                      timeline:
                        timeframe === "Custom range" ? timeline : "Daily",
                    });
                  }}
                  required={false}
                  options={timeframeOptions}
                  className="!h-[50px] text-[#163545]"
                />
              </div>

              {plotFilterData.timeframe === "Custom range" && (
                <div className="max-w-[280px] w-full mr-2">
                  <DateRangeTrestle
                    value={dateRage}
                    setValue={(val: any) => {
                      let timelineVal = "Daily";

                      // if (plotFilterData.timeframe === "Custom range") {
                      //   let temp = differenceInMonths(
                      //     val.endDate,
                      //     val.startDate
                      //   );

                      //   if (temp >= 1) {
                      //     timelineVal = "Monthly";
                      //   }
                      // }

                      setPlotFilterData({
                        ...plotFilterData,
                        timeline: timelineVal,
                      });

                      setDateRage({
                        startTime: val.startDate,
                        endTime: val.endDate,
                      });
                    }}
                  />
                </div>
              )}

              <TrestleButton
                onClick={() => {
                  FetchUsageChartData();
                  setBackPlotFilterData(plotFilterData);
                }}
                label="Apply Filter"
                type="primary"
                className="!h-[40px] !leading-[40px] !p-0 !min-w-[150px] !max-w-[150px] mt-auto ml-auto"
              />

              <TrestleButton
                onClick={() => {
                  exportCSV();
                }}
                label="Export to CSV"
                type="secondry"
                className="!h-[40px] !leading-[40px] !p-0 !min-w-[120px] !max-w-[120px] mt-auto ml-4"
              />
            </div>

            <div className="">
              <Line
                id="LineUsage"
                data={chartData}
                options={AppConstants.CHART_OPTIONS}
              // className="!w-full"
              />
            </div>
          </div>
        </>
      }
    </>
  );
}

export default YourUsageV2;
