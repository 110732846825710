import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { BrowserClient } from "@sentry/react";
import EnterprisePlanContent from "./EnterprisePlanContent";
import {
  ComponentLabelHeader,
  Text16pxBold,
  Text16pxMedium,
  Text20pxBold,
  Text20pxMedium,
} from "../../Shared/CommonComponents";
import TrestleButton from "../../Shared/TrestleButton";
import { ForwardArrow } from "../../../assets/svgs";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { useAppSelector } from "../../../app/hooks";

const EnterpriseTabComponent = () => {
  const companyRequirements = [
    "Volume-based discounts",
    "Enterprise support & account management",
    "Robust admin controls",
    "Customized to your specific needs",
    "Custom queries per second",
    "Advanced security support",
  ];

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );
  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        minHeight: "100%",
        background: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        gap: "60px",
        alignItems: "center",
        padding: "50px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "64px",
          alignItems: "center",
          maxWidth: "850px",
          width: "100%",
        }}
      >
        <ComponentLabelHeader style={{ textAlign: "center" }}>
          Customized solutions and support to meet your enterprise-level
          requirements.
        </ComponentLabelHeader>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            alignItems: "center",
            maxWidth: "850px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              borderBottom: "1px solid #163545",
              width: "100%",
            }}
          >
            <Text16pxBold c="#163545">What's Included</Text16pxBold>
          </Box>
          <Grid container spacing={"16px"}>
            {companyRequirements.map((item, index) => {
              return (
                <Grid item xs={12} sm={12} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "16px",
                      alignItems: "center",
                      padding: "12px 16px",
                      background: "#F5F5F5",
                    }}
                  >
                    <Box
                      sx={{
                        width: "12px",
                        height: "12px",
                        borderRadius: "100%",
                        background: "#0494B4",
                      }}
                    />
                    <Text16pxMedium c="#163545">{item}</Text16pxMedium>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "30px",
            width: "616px",
          }}
        >
          <TrestleButton
            onClick={() => {
              window.open(
                "https://meetings.hubspot.com/ana-trudeau/developer-portal-general-meeting",
                "_blank"
              );
              handleMixPanel(MixPanelEvents.WALLET_BOOK_MEETING, {
                userEmail: userData?.email,
              });
            }}
            label="Book a Meeting"
            type="primary"
            className="!h-[60px]"
          />
          <TrestleButton
            onClick={() => {
              window.open("https://trestleiq.com/contact/", "_blank");
              handleMixPanel(MixPanelEvents.WALLET_CONTACT_SALES, {
                userEmail: userData?.email,
              });
            }}
            label="Contact Us"
            type="contact"
            className="!h-[60px]"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EnterpriseTabComponent;
