import React, { useEffect, useState } from "react";
import InputTextField from "./InputTextField";
import { ComponentLabelHeader, TextLabel, TextValue } from "./CommonComponents";
import { ConstantTexts } from "../../constants/ConstantTexts";
import TrestleButton from "./TrestleButton";
import { InfoIcon, LockIconNew } from "../../assets/svgs";
import JsonDisplayComponent from "./JsonDisplayComponent";
import { AppConstants } from "../../constants/AppConstants";
import { MakeApiServiceCall } from "../UserMode/YourApiKey/YourApiKeyServices";
import { validatePhone, validationEmail } from "../../utils/validations";
import { useAppDispatch } from "../../app/hooks";
import { setToast } from "../../app/toastReducer";
import { handleMixPanel } from "../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../constants/MixPanelEvents";

interface ITestRequestFormProps {
  rowEle: any;
  currentEle: any;
  RequestAccess: any;
  activeApiKey: string;
  setIsLoading: any;
  disableDropDown: boolean;
  setDisableDropDown: React.Dispatch<React.SetStateAction<boolean>>;
}

const TestRequestForm = ({
  rowEle,
  currentEle,
  RequestAccess,
  activeApiKey,
  setIsLoading,
  disableDropDown,
  setDisableDropDown,
}: ITestRequestFormProps) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({
    productName: "",
    email: "",
    phone: "",
    street_line_1: "",
    city: "",
    state_code: "",
    postal_code: ""

  });
  const [formError, setFormError] = useState<any>({
    productName: "",
    email: "",
    phone: "",
  });
  const [response, setResponse] = useState<any>();
  const [status, setStatus] = useState<string>("Success");

  const [tryForFree, setTryForFree] = useState<boolean>(false);
  const [quotaLimitReached, setQuotaLimitReached] = useState<boolean>(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setResponse(null);
    setFormData({
      productName: "",
      email: "",
      phone: "",
    });
    setTryForFree(false);
    setQuotaLimitReached(false);
  }, [currentEle]);

  useEffect(() => {
    if (
      rowEle.status === "CAN_REQUEST" ||
      rowEle.status === "UNAVAILABLE" ||
      rowEle.status === "REMOVE" ||
      rowEle.status === "NOT_IN_USE" ||
      rowEle.status === "REQUESTED" ||
      rowEle.subscriptionStatus === false ||
      (rowEle.quotaLimit != 0 && rowEle.quotaLimit === rowEle.usageCountV2)
    ) {
      setFormData({
        email: "waidong.syrws@email.com",
        phone: "206-973-5100",
        street_line_1: "100 Syrws St Ste 1",
        postal_code: "98264",
        state_code: "WA",
        city: "Lynden",
        productName: "Waidong Syrws"
      });
      setTryForFree(true);
    }
  }, [currentEle]);

  const getSampleRequestStatus = () => {
    if (
      rowEle.status === "CAN_REQUEST" ||
      rowEle.status === "UNAVAILABLE" ||
      rowEle.status === "REMOVE" ||
      rowEle.status === "NOT_IN_USE" ||
      rowEle.status === "REQUESTED" ||
      rowEle.subscriptionStatus === false
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleSubmit = async () => {
    if (getSampleRequestStatus() || tryForFree) {
      handleMixPanel(MixPanelEvents.SUBMIT_TEST_REQUEST, {
        type: "Try For Free",
        productName: rowEle.apiName,
      });
      switch (rowEle.apiName) {
        case "Caller Identification 3.1":
          setResponse(
            AppConstants.DUMMY_API_RESPONSE["Caller Identification 3.1"]
          );
          break;
        case "Caller Identification 3.0":
          setResponse(
            AppConstants.DUMMY_API_RESPONSE["Caller Identification 3.0"]
          );
          break;
        case "Phone Intel 3.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Phone Intel 3.0"]);
          break;
        case "Real Contact 1.1":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Real Contact 1.1"]);
          break;
        case "Real Contact 1.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Real Contact 1.0"]);
          break;
        case "Reverse Phone 3.2":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Reverse Phone 3.2"]);
          break;
        case "Reverse Phone 3.1":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Reverse Phone 3.1"]);
          break;
        case "Reverse Phone 3.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Reverse Phone 3.0"]);
          break;
        case "Smart CNAM 3.1":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Smart CNAM 3.1"]);
          break;
        case "Phone Data 1.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Phone Data 1.0"]);
          break;
        case "Find Person 3.1":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Find Person 3.1"]);
          break;
        case "Find Person 3.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Find Person 3.0"]);
          break;
        case "Reverse Address 3.1":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Reverse Address 3.1"]);
          break;
        case "Reverse Address 3.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Reverse Address 3.0"]);
          break;
        case "Location Intel 3.0":
          setResponse(AppConstants.DUMMY_API_RESPONSE["Location Intel 3.0"]);
          break;
      }
    } else {
      handleMixPanel(MixPanelEvents.SUBMIT_TEST_REQUEST, {
        type: "Make Test Request",
        productName: rowEle.apiName,
      });
      setIsLoading(true);
      const row = rowEle;
      const apiKey = activeApiKey;
      MakeApiServiceCall({ row, apiKey, formData })
        .then((data: any) => {
          setResponse(data.data);
          setIsLoading(false);
          setStatus("Success")
        })
        .catch((error: any) => {
          setStatus("Failed")

          if (error?.response?.status === 429) {
            setQuotaLimitReached(true);
            setTryForFree(true);
            setFormData({
              email: "waidong.syrws@email.com",
              phone: "206-973-5100",
              street_line_1: "100 Syrws St Ste 1",
              postal_code: "98264",
              state_code: "WA",
              city: "Lynden",
              productName: "Waidong Syrws"
            });
            setResponse(null);
          } else if (error?.response?.status === 403 || error?.response?.status === 500) {
            setResponse(error?.response?.data);
          }else{
            setResponse({'message':'Internal Server Error'});
          }
          setIsLoading(false);
        });
    }
  };

  const handleTryRequestClick = () => {
    setTryForFree(true);
    setFormData({
      productName: "Waidong Syrws",
      email: "waidong.syrws@email.com",
      phone: "206-973-5100",
      
    });
  };

  const showEmailField = () => {
    if (rowEle.productName === "Real Contact API") {
      return true;
    } else {
      return false;
    }
  };

  const showPhoneField = () => {
    if (rowEle.productName !== "Find Person API" && rowEle.productName !== 'Reverse Address API' && rowEle.productName !== 'Address Validation API') {
      return true;
    } else {
      return false;
    }
  };

  const showNameField = () => {
    if (rowEle.productName === "Real Contact API") {
      return true;
    } else {
      return false;
    }
  };

  const showAddressFields = () => {
    if (rowEle.productName === "Reverse Address API" || rowEle.productName === "Find Person API" || rowEle.productName === 'Address Validation API') {
      return true;
    } else {
      return false;
    }
  };



  useEffect(() => {
    var element: any = document.getElementById(currentEle.usagePlanId);
  }, [currentEle.usagePlanId]);

  return (
    <>
      {rowEle.usagePlanId === currentEle.usagePlanId && (
        <div
          id={`${rowEle.usagePlanId}`}
          className="flex flex-col gap-[20px] p-[16px] bg-[#F5F5F5] "
        >
          {getSampleRequestStatus() ? (
            <ComponentLabelHeader>Sample Request</ComponentLabelHeader>
          ) : (
            <ComponentLabelHeader>Test Request</ComponentLabelHeader>
          )}
          {getSampleRequestStatus() && rowEle.expired === "EXPIRED" ? (
            <TextLabel className="flex gap-[5px]">
              {ConstantTexts.TEST_REQUEST_EXPIRED_TEXT}
              <TextValue
                className="underline text-[#0494B4] cursor-pointer"
                onClick={() => {
                  window.open("https://trestleiq.com/contact/");
                }}
              >
                Contact Us
              </TextValue>
            </TextLabel>
          ) : (
            <>
              {getSampleRequestStatus() && (
                <TextLabel className="flex gap-[5px]">
                  {ConstantTexts.TEST_REQUEST_TEXT}
                  {rowEle.status === "CAN_REQUEST" && (
                    <TextValue
                      className="underline text-[#0494B4] cursor-pointer"
                      onClick={() => {
                        RequestAccess(rowEle);
                      }}
                    >
                      Request Access
                    </TextValue>
                  )}
                </TextLabel>
              )}
            </>
          )}
         <div className="flex gap-[20px]">
            {showNameField() && (
              <InputTextField
                label={"Name"}
                name={"productName"}
                value={formData}
                error={formError}
                required={false}
                onChange={handleChange}
                className="bg-white max-w-[400px]"
                disabled={getSampleRequestStatus() || tryForFree}
                endAdornment={
                  getSampleRequestStatus() || tryForFree ? LockIconNew : null
                }
              />
            )}

            {showPhoneField() && (
              <InputTextField
                label={"Phone"}
                name={"phone"}
                value={formData}
                error={formError}
                required={false}
                onChange={handleChange}
                className="bg-white max-w-[400px]"
                disabled={getSampleRequestStatus() || tryForFree}
                endAdornment={
                  getSampleRequestStatus() || tryForFree ? LockIconNew : null
                }
              />
            )}

            {showEmailField() && (
              <InputTextField
                label={"Email"}
                name={"email"}
                value={formData}
                error={formError}
                required={false}
                onChange={handleChange}
                className="bg-white max-w-[400px]"
                disabled={getSampleRequestStatus() || tryForFree}
                endAdornment={
                  getSampleRequestStatus() || tryForFree ? LockIconNew : null
                }
              />
            )}

            {showAddressFields() && (
              <div
                style={{
                  display: "flex",
                  flexDirection:
                    rowEle.productName === "Find Person API" ? "column" : "row",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <div
                  className="flex gap-[20px]"
                  style={{
                    width:
                      rowEle.productName === "Find Person API" ? "100%" : "30%",
                  }}
                >
                  {rowEle.productName === "Find Person API" && (
                    <InputTextField
                      label={"Name"}
                      name={"productName"}
                      value={formData}
                      error={formError}
                      required={false}
                      onChange={handleChange}
                      className="bg-white"
                      disabled={getSampleRequestStatus() || tryForFree}
                      endAdornment={
                        getSampleRequestStatus() || tryForFree
                          ? LockIconNew
                          : null
                      }
                    />
                  )}
                  <InputTextField
                    label={"Street Line 1"}
                    name={"street_line_1"}
                    value={formData}
                    error={formError}
                    required={false}
                    onChange={handleChange}
                    className="bg-white"
                    disabled={getSampleRequestStatus() || tryForFree}
                    endAdornment={
                      getSampleRequestStatus() || tryForFree
                        ? LockIconNew
                        : null
                    }
                  />
                </div>
                <div
                  className="flex gap-[20px]"
                  style={{
                    width:
                      rowEle.productName === "Find Person API" ? "100%" : "70%",
                  }}
                >
                  <InputTextField
                    label={"City"}
                    name={"city"}
                    value={formData}
                    error={formError}
                    required={false}
                    onChange={handleChange}
                    className="bg-white"
                    disabled={getSampleRequestStatus() || tryForFree}
                    endAdornment={
                      getSampleRequestStatus() || tryForFree
                        ? LockIconNew
                        : null
                    }
                  />
                  <InputTextField
                    label={"State"}
                    name={"state_code"}
                    value={formData}
                    error={formError}
                    required={false}
                    onChange={handleChange}
                    className="bg-white"
                    disabled={getSampleRequestStatus() || tryForFree}
                    endAdornment={
                      getSampleRequestStatus() || tryForFree
                        ? LockIconNew
                        : null
                    }
                  />
                  <InputTextField
                    label={"Postal Code"}
                    name={"postal_code"}
                    value={formData}
                    error={formError}
                    required={false}
                    onChange={handleChange}
                    className="bg-white"
                    disabled={getSampleRequestStatus() || tryForFree}
                    endAdornment={
                      getSampleRequestStatus() || tryForFree
                        ? LockIconNew
                        : null
                    }
                  />
                </div>
              </div>
            )}

            <TrestleButton
              onClick={handleSubmit}
              label="Submit"
              disabled={disableDropDown}
              type="primary"
              className="mt-auto !w-[fit-content]"
            />
          </div>
          {quotaLimitReached ||
            (rowEle.quotaLimit != 0 &&
              rowEle.quotaLimit === rowEle.usageCountV2) ? (
            <TextLabel className="flex gap-[5px] items-center mt-[10px]">
              <span>{InfoIcon}</span> You have reached your usage quota.{" "}
              <span
                className="underline text-[#0494B4] cursor-pointer font-bold"
                onClick={() => {
                  window.open("https://trestleiq.com/contact/");
                }}
              >
                Contact Us
              </span>{" "}
              to upgrade or{" "}
              <span
                className="underline text-[#0494B4] cursor-pointer font-bold"
                onClick={() => handleTryRequestClick()}
              >
                Try a Sample Request for Free
              </span>{" "}
              instead.
            </TextLabel>
          ) : (
            <></>
          )}
          {response && (
            <>
              <ComponentLabelHeader>Sample Response</ComponentLabelHeader>
              <JsonDisplayComponent
                width="650px"
                height="350px"
                data={response}
                status={status}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default TestRequestForm;
