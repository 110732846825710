import React from "react";
import { Box } from "@mui/material";
import { TrestleLogo } from "../../assets/svgs";
import {
  APIKEY_LABEL,
  Text14pxBold,
  Text14pxMedium,
  Text16pxBold,
  TextLabel,
  UserDetailSubHeader,
  WalletBalanceText,
} from "./CommonComponents";

interface ProductPricingDetailsCardProps {
  productName: string;
  productPrice: string;
}

const ProductPricingDetailsCard = ({
  productName = "",
  productPrice = "",
}: ProductPricingDetailsCardProps) => {
  const getBackgroundColor = () => {
    switch (productName) {
      case "Phone Validation":
        return "#44DAD3";
      case "Real Contact":
        return "#44DAD3";
      case "Reverse Phone":
        return "#0494B4";
      case "Reverse Address":
        return "#0494B4";
      case "Find Person":
        return "#0494B4";
      case "Smart CNAM":
        return "#F78331";
      case "Caller Identification":
        return "#F78331";
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: "#F5F5F5",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "14px 10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Box
          sx={{
            height: "14px",
            width: "14px",
            background: `${getBackgroundColor()}`,
          }}
        />
        <Text14pxBold c="#163545">{productName}</Text14pxBold>
      </Box>
      <Box display={"flex"} sx={{ alignItems: "baseline", gap: "5px" }}>
        <Text14pxBold c="#163545">{`$${productPrice}`}</Text14pxBold>
        <Text14pxBold>/query</Text14pxBold>
      </Box>
    </Box>
  );
};

export default ProductPricingDetailsCard;
