import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  MailIcon,
  HamburgerIcon,
  LinkIcon,
  KeyIcon,
  PieChartIcon,
  StackIcon,
  BookIcon,
  LockIcon,
  UserIcon,
  NeedHelpIcon,
} from "../../../assets/svgs";
import { RouteConstants } from "../../../constants/RouteConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { AppConstants } from "../../../constants/AppConstants";
import { useAppSelector } from "../../../app/hooks";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";

const drawerWidth = 315;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // width: `calc(${theme.spacing(7)} + 1px)`,
  width: 90,
  [theme.breakpoints.up("sm")]: {
    // width: `calc(${theme.spacing(8)} + 1px)`,
    width: 90,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ListElement = (
  index: number,
  text: string,
  action: any = () => {},
  isLink: boolean = false,
  icon: any = MailIcon,
  isSelected: boolean = false,
  open: boolean = false
) => {
  return (
    <ListItem
      key={`Route-${index}`}
      disablePadding
      sx={{ display: "block", width: "100%" }}
      className="mb-[1px]"
      onClick={() => {
        action();
      }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          width: "100%",
          backgroundColor: isSelected ? "rgba(68, 218, 211, 0.2)" : "",
        }}
        className="!p-0"
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
            background: isSelected ? "#1D4E5A" : "rgb(68 218 211 / 6%)",
            color: "#44DAD3",
          }}
          className={` p-[35px]`}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={
            <div className="flex leading-4">
              <span className="mr-2">{text}</span> {isLink && LinkIcon}
            </div>
          }
          sx={{ opacity: open ? 1 : 0 }}
          className="!text-base !text-[#37A8A8]"
          style={{ display: open ? "block" : "none", width: "100%" }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default function TrestleAppDrawer(props: any) {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const compoent_div = document.getElementById("comp-render");
    if (compoent_div) {
      compoent_div?.scrollTo(0, 0);
    }
  }, [location]);

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const clickDocLogMixpanel = () => {
    handleMixPanel(MixPanelEvents.CLICK_DOCUMENTATION, {
      userId: userData?.id,
      userEmail: userData?.email,
    });
  };

  const AdminRoutes = [
    {
      text: "Product Access",
      action: () => navigate(RouteConstants.ROUTE_ADMIN_PRODUCT_ACCESS),
      path: RouteConstants.ROUTE_ADMIN_PRODUCT_ACCESS,
      icon: LockIcon,
    },
    {
      text: "All Users",
      action: () => navigate(RouteConstants.ROUTE_ADMIN_ALL_USERS),
      path: RouteConstants.ROUTE_ADMIN_ALL_USERS,
      icon: UserIcon,
    },
    {
      text: "Usage",
      action: () => navigate(RouteConstants.ROUTE_ADMIN_USAGES),
      path: RouteConstants.ROUTE_ADMIN_USAGES,
      icon: PieChartIcon,
    },
    // {
    //   text: "Teams",
    //   action: () => navigate(RouteConstants.ROUTE_ADMIN_TEAMS),
    //   path: RouteConstants.ROUTE_ADMIN_TEAMS,
    //   icon: TeamsIcon,
    // },
    {
      text: "Documentation",
      action: () => {
        clickDocLogMixpanel();
        window.open(AppConstants.TRESTLE_DOCUMENT_URL);
      },
      isLink: true,
      icon: BookIcon,
    },
  ];

  const userRoutes = [
    {
      text: "Your API Keys",
      action: () => navigate(RouteConstants.ROUTE_YOUR_API_KEY),
      path: RouteConstants.ROUTE_YOUR_API_KEY,
      icon: KeyIcon,
    },
    {
      text: "Your Usage",
      action: () => navigate(RouteConstants.ROUTE_YOUR_USAGE),
      path: RouteConstants.ROUTE_YOUR_USAGE,
      icon: PieChartIcon,
    },
    // {
    //   text: "Your Usage",
    //   action: () => navigate(RouteConstants.ROUTE_YOUR_USAGE_V2),
    //   path: RouteConstants.ROUTE_YOUR_USAGE_V2,
    //   icon: PieChartIcon,
    // },
    {
      text: "Pricing Plans",
      action: () => {
        navigate(RouteConstants.ROUTE_PRICING_PAGE);
      },
      icon: StackIcon,
    },
    {
      text: "Documentation",
      action: () => {
        clickDocLogMixpanel();
        window.open(AppConstants.TRESTLE_DOCUMENT_URL);
      },
      isLink: true,
      icon: BookIcon,
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [sidebarRoutes, setSidebarRoutes] = React.useState<any>([
    ...userRoutes,
  ]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const ListElement = (
    index: number,
    text: string,
    action: any = () => {},
    isLink: boolean = false,
    icon: any = MailIcon,
    isSelected: boolean = false
  ) => {
    return (
      <ListItem
        key={`Route-${index}`}
        disablePadding
        sx={{ display: "block", width: "100%" }}
        className="mb-[1px]"
        onClick={() => {
          action();
        }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            width: "100%",
            backgroundColor: isSelected ? "rgba(68, 218, 211, 0.2)" : "",
          }}
          className="!p-0"
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
              background: isSelected ? "#1D4E5A" : "rgb(68 218 211 / 6%)",
              color: "#44DAD3",
            }}
            className={` p-[35px]`}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={
              <div className="flex leading-4">
                <span className="mr-2">{text}</span> {isLink && LinkIcon}
              </div>
            }
            sx={{ opacity: open ? 1 : 0 }}
            className="!text-base !text-[#37A8A8]"
            style={{ display: open ? "block" : "none", width: "100%" }}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        className={`!bg-[#163545] ${
          open ? "trestle-drawer" : "trestle-drawer-fit"
        }`}
        // sx={{
        //   width: open ? "315px" : "90px !important",
        // }}
      >
        <DrawerHeader className="app-bar"></DrawerHeader>
        <List
          className="!bg-[#163545] h-full w-full"
          // sx={{
          //   width: open ? "315px" : "fit-content",
          // }}
        >
          <ListItem
            key={`Route-header`}
            disablePadding
            sx={{ width: "100%" }}
            className="mb-[1px] flex"
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
              className="p-[35px]"
            >
              {HamburgerIcon}
            </ListItemIcon>
            <ListItemText
              primary={props.isAdmin ? "Admin Portal" : "Developer Portal"}
              sx={{ opacity: open ? 1 : 0 }}
              className="!text-base !text-[#37A8A8]"
              style={{ display: open ? "block" : "none", width: "100%" }}
            />
          </ListItem>
          {props.isAdmin
            ? AdminRoutes.map((ele: any, index: number) =>
                ListElement(
                  index,
                  ele.text,
                  ele.action,
                  ele?.isLink,
                  ele?.icon,
                  window.location.pathname === ele.path
                )
              )
            : userRoutes.map((ele: any, index: number) =>
                ListElement(
                  index,
                  ele.text,
                  ele.action,
                  ele?.isLink,
                  ele?.icon,
                  window.location.pathname === ele.path
                )
              )}
          {!props.isAdmin && (
            <div className="w-full">
              {ListElement(
                99,
                "Need Help?",
                () => {
                  window.open("https://trestleiq.com/contact/", "_blank");
                },
                true,
                NeedHelpIcon,
                false
              )}
            </div>
          )}
        </List>
      </Drawer>
      <div className="w-full !overflow-hidden">{props.children}</div>
    </Box>
  );
}
