import React, { useState } from "react";
import TrestleButton from "../../Shared/TrestleButton";
import DialogPopup from "../../Shared/DialogPopup";
import { updateTeamInvitation } from "../../../http/teams-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import { useAppDispatch } from "../../../app/hooks";

interface InvitationPopupProps {
  open: "INVITATION" | "ACCEPT_INVITE" | "";
  setInvitationPopup: any;
  inviteDetails: any;
  setLoading: any;
}

export default function InvitationPopup({
  open,
  setInvitationPopup,
  inviteDetails,
  setLoading,
}: InvitationPopupProps) {
  const dispatch = useAppDispatch();

  const UpdateTeamInvitation = async (action: "ACCEPTED" | "DENIED") => {
    let payload: any = {
      invitation_id: inviteDetails.id,
      requester_user_id: inviteDetails.userId,
      invitation_action: action,
    };
    setLoading(true);
    const response = await updateTeamInvitation(payload);

    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setLoading(false);

      if (action === "ACCEPTED") {
        localStorage.removeItem("userData");
        sessionStorage.setItem("INVITE-UPDATE", "ACCEPTED");
        window.location.reload();
        setInvitationPopup("");
      } else {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: "Invitation has been rejected successfully",
          })
        );
        setInvitationPopup("");
      }
    } else {
      setLoading(false);
      console.error(response);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.message,
        })
      );
    }
  };

  return (
    <DialogPopup
      open={!!open}
      onClose={(val: any) => {
        setInvitationPopup("");
      }}
      closeButton={true}
      dialogStyleClass="dialogInvitation"
      title={""}
      className="!p-0"
      component={
        <div>
          <div className="w-full py-8 px-10 bg-[#F3F5F6] text-[#163545] mb-6">
            <div className="text-xl font-bold">Team Invitation</div>
          </div>
          <div className="p-10 pt-0 w-full text-lg">
            <div>
              {open === "INVITATION" ? (
                <>
                  <div className="mb-10">
                    You have been invited to join the below Team Account on
                    Trestle.
                  </div>

                  <div className="flex mb-2">
                    <p className="font-semibold mr-2">Company : </p>{" "}
                    {inviteDetails?.company_name || " sample_company"}
                  </div>
                  <div className="flex">
                    <p className="font-semibold mr-2">Team : </p>{" "}
                    {inviteDetails?.team_name || " sample_team"}
                  </div>
                </>
              ) : (
                <div className="mb-15">
                  Are you sure you want to <b>ACCEPT</b> the Team Invitation?
                </div>
              )}
            </div>

            <div className="mt-10 mb-14">
              Note: Once added to the Team, you will be able to access the Team
              Account and your existing API Keys will be revoked
            </div>

            {open === "INVITATION" ? (
              <div className="flex">
                <TrestleButton
                  onClick={() => {
                    setInvitationPopup("ACCEPT_INVITE");
                  }}
                  label="Accept"
                  type="primary"
                  className="max-w-[180px] !h-[40px] !py-0 !pt-0 ml-auto mr-auto"
                />
                <TrestleButton
                  onClick={() => {
                    // setInvitationPopup("");
                    UpdateTeamInvitation("DENIED");
                  }}
                  label="Deny"
                  type="secondry"
                  className="max-w-[180px] !h-[40px] !py-0 !pt-0 mr-auto"
                />
              </div>
            ) : (
              <div className="flex mt-40">
                <TrestleButton
                  onClick={() => {
                    // setInvitationPopup("");
                    UpdateTeamInvitation("ACCEPTED");
                  }}
                  label="Confirm"
                  type="primary"
                  className="max-w-[180px] !h-[40px] !py-0 !pt-0 ml-auto mr-auto"
                />
                <TrestleButton
                  onClick={() => {
                    setInvitationPopup("INVITATION");
                  }}
                  label="Go Back"
                  type="secondry"
                  className="max-w-[180px] !h-[40px] !py-0 !pt-0 mr-auto"
                />
              </div>
            )}
          </div>
        </div>
      }
      closeOnOut={true}
    />
  );
}
