import { useEffect, useState } from "react";
import Accordions from "../../../Shared/Accordions/Accordions";
import {
  APIKEY_LABEL,
  TextLabel,
  TextValue,
} from "../../../Shared/CommonComponents";
import InputTextField, {
  InputErrortext,
  InputLabel,
} from "../../../Shared/InputTextField";
import TrestleTable, {
  TableHeaderProps,
} from "../../../Shared/TrestleTable/TrestleTable";
import DropDown from "../../DropDown";
import {
  CheckBoxComponent,
  ToggleSwitchComponent,
} from "../../ToggleComponents";
import ProductIcon from "../../../../assets/product_icon.svg";
import TrestleButton from "../../TrestleButton";
import moment from "moment";
import { InfoIcon } from "../../../../assets/svgs";
import WaringPopup from "../../WarningPopup";
import styled from "@emotion/styled";
import { isEqual } from "lodash";
import { AppConstants } from "../../../../constants/AppConstants";
import CalenderComp from "../../CalenderComp";
import { Tooltip } from "@mui/material";
import { ConstantTexts } from "../../../../constants/ConstantTexts";
import CustomAttributes from "./CustomAttributes";
import MaxioSubscriptionDetails from "../../../Maxio/MaxioSubscriptionDetails";
import { getUTCDate } from "../../../../utils/helpers";
import Alerttriangle from "../../../../assets/Alert-Triangle.svg";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setToast } from "../../../../app/toastReducer";

interface APIAccordionItemProps {
  key: string;
  expanded: string | boolean;
  setExpanded: any;
  index: number;
  APIKeyData: any;
  RevokeAPIKey: any;
  setEditIndex: any;
  editIndex: any;
  UpdateSubscriptionCall: any;
  isLoading?: boolean;
  customAttributesDropdownValues: any;
  UpdateSubscriptionName: any;
  userData: any;
}

function APIAccordionItem({
  key,
  expanded,
  setExpanded,
  index,
  APIKeyData,
  RevokeAPIKey,
  setEditIndex,
  editIndex,
  UpdateSubscriptionCall,
  isLoading = false,
  customAttributesDropdownValues,
  UpdateSubscriptionName,
  userData,
}: APIAccordionItemProps) {
  const [keyData, setKeyData] = useState<any>({});
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const [formData, setFormData] = useState<any>({
    planType: "",
    qps: "",
    expiryDate: "",
    creationDate: "",
    limit: "",
    period: "",
    isRestricted: false,
  });
  const dispatch = useAppDispatch();
  const [productsData, setProductsData] = useState<any>([]);
  const [customAttributes, setCustomAttributes] = useState<any>([]);
  const [maxioSubscriptions, setMaxioSubscriptions] = useState<any>([]);
  const [changesProducts, setChangesProducts] = useState<any>([]);

  const [updatable, setUpdatable] = useState<boolean>(false);

  const [newKeyName, setNewKeyName] = useState<string>("");

  const [totalUsageCount, setTotalUsageCount] = useState<number>(0);
  const [remainingUsageCount, setRemainingUsageCount] = useState<number>(0);
  const [availableProductOptions, setAvailableProductOptions] = useState<any[]>(
    []
  );

  const [formDataError, setFormDataError] = useState<any>({
    planType: "",
    qps: "",
    products: [],
    expiryDate: "",
    creationDate: "",
    limit: "",
  });

  const [customDropError, setCustomDropError] = useState<string>("");

  const currentUserData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  useEffect(() => {
    setKeyData(APIKeyData);
    setDataToFormData();
  }, []);

  const setDataToFormData = () => {
    const {
      planType = "",
      qps = "",
      products = [],
      expiryDate = "",
      creationDate = "",
      limit = null,
      period = "",
      apiKeyName = "",
      customAttributes: Attributes = [],
      maxioComponents: MaxioSubscriptions = [],
    } = APIKeyData;

    setFormData({
      planType,
      qps,
      expiryDate: expiryDate ? getUTCDate(expiryDate) : null,
      creationDate: getUTCDate(creationDate),
      limit: limit === 0 ? null : limit,
      period,
      isRestricted: limit === 0 || limit === null ? false : true,
    });

    let totalUsageV2: number = 0;
    products.forEach((product: any) => {
      totalUsageV2 += product.usageCountV2;
    });
    let remainingUsage: number = limit === 0 ? -1 : limit - totalUsageV2;

    setTotalUsageCount(totalUsageV2);
    setRemainingUsageCount(remainingUsage);

    setProductsData(products);

    setCustomAttributes(JSON.parse(JSON.stringify(Attributes)));
    setMaxioSubscriptions(JSON.parse(JSON.stringify(MaxioSubscriptions)));

    setNewKeyName(apiKeyName);

    setKeyData({
      planType,
      qps,
      products,
      expiryDate: expiryDate ? getUTCDate(expiryDate) : null,
      creationDate: getUTCDate(creationDate),
      limit: limit === 0 ? null : limit,
      period,
      customAttributes: Attributes,
      maxioSubscriptions: MaxioSubscriptions,
      isRestricted: limit === 0 || limit === null ? false : true,
    });

    setFormDataError({
      planType: "",
      qps: "",
      products: [],
      expiryDate: "",
      creationDate: "",
      limit: "",
    });
  };

  const {
    apiKeyName = "",
    apiKey = "",
    planType = "",
    expiryDate = "",
    creationDate = "",
    subscriptionId = "",
  } = APIKeyData || {};

  const headCells1: readonly TableHeaderProps[] = [
    {
      id: "apiName",
      numeric: false,
      disablePadding: false,
      label: "Product Name",
      width: "40%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <div className="flex">
            <img src={ProductIcon} alt="product icon" />
            <div className="font-bold text-base text-[#163545] ml-3">
              {ele.apiName}
            </div>
          </div>
        );
      },
      sortable: true,
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Access",
      isCustomElements: true,
      width: "20%",
      customElements: (ele: any) => {
        return (
          <ToggleSwitchComponent
            disabled={APIKeyData.status === AppConstants.SUBSCRIPTION_EXPIRED}
            value={ele.status === "AVAILABLE"}
            toggleCheck={() => {
              let count = 0;
              productsData.map((item: any) => {
                if (item.status === "AVAILABLE") {
                  count++;
                }
              });
              if (count === 10 && ele.status !== "AVAILABLE") {
                dispatch(
                  setToast({
                    open: true,
                    severity: AppConstants.TOAST_ERROR,
                    msg: ConstantTexts.PRODUCT_ACCESS_LiMIT,
                  })
                );
                return;
              }
              let temp = productsData.map((item: any) => {
                let d = { ...item };
                if (item.apiName === ele.apiName) {
                  d.status =
                    ele.status === "AVAILABLE" ? "UNAVAILABLE" : "AVAILABLE";
                  let cData = [...changesProducts];

                  let t = cData.filter(
                    (item: any) => item.apiName === ele.apiName
                  );
                  if (t.length === 0) {
                    cData.push(d);
                    setChangesProducts(cData);
                  } else {
                    let sample: any = [];
                    cData.forEach((item: any) => {
                      if (item.apiName !== ele.apiName) {
                        sample.push(item);
                      }
                    });
                    setChangesProducts(sample);
                  }
                }
                return d;
              });
              setProductsData(temp);
            }}
          />
        );
      },
      sortable: false,
    },
    // version 2
    // {
    //   id: "usageCount",
    //   numeric: true,
    //   disablePadding: false,
    //   label: (
    //     <div className="flex items-center">
    //       <div>This Month’s Usage</div>
    //       <Tooltip
    //         title={ConstantTexts.USAGE_TABLE_HEADER_INFO}
    //         arrow
    //         sx={{ width: "100px" }}
    //       >
    //         <span className="ml-[5px]">{InfoIcon}</span>
    //       </Tooltip>
    //     </div>
    //   ),
    //   isCustomElements: false,
    //   width: "20%",
    //   customElements: (ele: any) => { },
    //   sortable: true,
    //   className: "!text-right !pr-[45px]",
    //   headClassName: "!text-right !pr-[20px]",
    // },
    {
      id: "usageCountV2",
      numeric: true,
      disablePadding: false,
      label: (
        <div className="flex items-center">
          <div>This Month’s Usage</div>

          <Tooltip
            title={ConstantTexts.USAGE_TABLE_HEADER_INFO}
            arrow
            sx={{ width: "100px" }}
          >
            <span className="ml-[5px]">{InfoIcon}</span>
          </Tooltip>
        </div>
      ),
      isCustomElements: false,
      width: "20%",
      customElements: (ele: any) => {},
      sortable: true,
      className: "!text-right !pr-[45px]",
      headClassName: "!text-right !pr-[20px]",
    },
  ];

  const panel = `panel${index}`;

  const onChangeHandler = (e: any) => {
    const { name, value } = e.target;

    if (name === "qps" || name === "limit") {
      const reg = /^[0-9]*$/;
      if (!reg.test(value) || value === " ") {
        return;
      }
    }

    setFormData({ ...formData, [name]: value });
    setFormDataError({ ...formDataError, [name]: "" });
  };

  useEffect(() => {
    if (apiKeyName) {
      let obj = {
        ...formData,
        products: productsData,
        customAttributes: customAttributes,
        maxioSubscriptions: maxioSubscriptions,
      };
      let temp = isEqual(keyData, obj);
      let Attribute = isEqual(customAttributes, APIKeyData.customAttributes);

      let keyNameChange = newKeyName !== apiKeyName;
      setUpdatable(!temp || !Attribute || keyNameChange);
      setEditIndex(!temp || !Attribute ? index : null);
    }

    const availableOptions = productsData
      .filter((item: any) => item.status === "AVAILABLE")
      .map((item: any) => item.apiName);
    setAvailableProductOptions(availableOptions);
  }, [
    formData,
    productsData,
    customAttributes,
    maxioSubscriptions,
    newKeyName,
    productsData,
  ]);

  const getUpdateSubscriptionPayload = () => {
    const { qps, expiryDate, limit, period, planType } = formData;
    const { products } = APIKeyData;
    let productToUpdate: any = {};
    let alreadyExistProducts: any = {};

    changesProducts.forEach((item: any) => {
      if (item.apiName) {
        productToUpdate[item.apiName] =
          item.status === "AVAILABLE" ? "ADD" : "REMOVE";
      }
    });

    products.forEach((item: any) => {
      if (!Object.keys(productToUpdate).includes(item.apiName)) {
        if (item.status === "AVAILABLE") {
          alreadyExistProducts[item.apiName] = "ADD";
        }
      }
    });

    let payload: any = {
      planType,
      email: "",
      qps: qps === null ? 0 : qps,
      limit: limit === null ? 0 : limit,
      productsStatus: { ...productToUpdate, ...alreadyExistProducts },
      period,
      subscriptionId: APIKeyData.subscriptionId,
      isNoEndDateEnabled: expiryDate === null ? true : false,
      expiryDate: expiryDate ? moment(expiryDate).format("YYYY-MM-DD") : null,
      adminEmail: currentUserData.email,
    };

    return payload;
  };

  const handleValidation = () => {
    let flag = true;
    const { qps, expiryDate, limit, period, planType, isRestricted } = formData;

    let temp: any = {};

    if (planType === null || planType === "") {
      setFormDataError({ ...formDataError, planType: "Plan required" });
      flag = false;
      return flag;
    }
    if (planType === "ENTERPRISE" || planType === "TRIAL") {
      temp = {
        qps:
          qps === "" || qps === null || qps <= 0
            ? AppConstants.REQUIRED_FILED_ERROR
            : "",
        limit:
          isRestricted && (limit === "" || limit === null || limit <= 0)
            ? AppConstants.REQUIRED_FILED_ERROR
            : "",
      };

      if (temp?.qps !== "" || temp?.limit !== "") {
        flag = false;
      }

      // if (customDropError) {
      //   flag = false;
      // }

      let tempCA = [...customAttributes];
      customAttributes.forEach((item: any, index: number) => {
        if (item?.error || item?.customValue === "" || item?.flag === "") {
          if (item?.customValue === "")
            tempCA[index]["customError"] = AppConstants.REQUIRED_FILED_ERROR;

          if (item?.flag === "")
            tempCA[index]["error"] = AppConstants.REQUIRED_FILED_ERROR;

          flag = false;
        }
      });

      let tempMS = [...maxioSubscriptions];
      maxioSubscriptions.forEach((item: any, index: number) => {
        if (
          item?.maxioSubscriptionId === "" ||
          item?.componentId === "" ||
          item?.pricePointId === ""
        ) {
          if (item?.maxioSubscriptionId === "")
            tempMS[index]["errorMaxioSubscriptionId"] =
              AppConstants.REQUIRED_FILED_ERROR;

          if (item?.componentId === "")
            tempMS[index]["errorComponentId"] =
              AppConstants.REQUIRED_FILED_ERROR;

          if (item?.pricePointId === "")
            tempMS[index]["errorPricePointId"] =
              AppConstants.REQUIRED_FILED_ERROR;

          flag = false;
        }
      });

      if (newKeyName.trim() === "") {
        temp["apiKeyName"] = AppConstants.REQUIRED_FILED_ERROR;
        flag = false;
      }

      setCustomAttributes(tempCA);
      setMaxioSubscriptions(tempMS);
      setFormDataError({ ...formDataError, ...temp });
      return flag;
    }

    temp["planType"] =
      planType === null || planType === ""
        ? AppConstants.REQUIRED_FILED_ERROR
        : "";

    if (temp.planType !== "") {
      flag = false;
    }

    setFormDataError(temp);
    return flag;
  };

  const UpdateSubscription = (isRenewSubscription = false) => {
    if (handleValidation()) {
      let payload = getUpdateSubscriptionPayload();
      payload["customAttributes"] = customAttributes;
      payload["maxioComponents"] = maxioSubscriptions;
      payload["apiKey"] = APIKeyData.apiKey;
      if (isRenewSubscription) {
        payload["selfServeRenew"] = true;
      }

      let obj = {
        ...formData,
        products: productsData,
        customAttributes: customAttributes,
        maxioSubscriptions: maxioSubscriptions,
      };
      let temp = isEqual(keyData, obj);
      let Attribute = isEqual(customAttributes, APIKeyData.customAttributes);

      if (newKeyName !== APIKeyData.apiKeyName) {
        if (!temp || !Attribute) {
          UpdateSubscriptionName(APIKeyData.subscriptionId, newKeyName, () => {
            UpdateSubscriptionCall(payload, () => {
              setUpdatable(false);
            });
          });
        } else {
          UpdateSubscriptionName(APIKeyData.subscriptionId, newKeyName);
        }
      } else {
        UpdateSubscriptionCall(payload, () => {
          setUpdatable(false);
        });
      }
    }
  };
  return (
    <>
      <Accordions
        disabled={editIndex}
        key={key}
        expanded={expanded}
        setExpanded={(val: string) => {
          if (expanded !== panel) {
            setExpanded(panel);
          } else if (val === "close") {
            setExpanded(val);
          }
        }}
        index={index}
        summary={
          <div className="w-full">
            <div className="flex w-full">
              {expanded === panel ? (
                <div className="flex w-full gap-2">
                  <div className="flex flex-col">
                    <div className="text-[13px] font-normal text-[#5E6E78] leading-10 mr-3">
                      Api key name
                    </div>
                    <div
                      className={`min-w-[250px] ${
                        formDataError.apiKeyName && "-mt-[15px]"
                      }`}
                    >
                      <InputTextField
                        name={"apiKeyName"}
                        label={""}
                        value={{ apiKeyName: newKeyName }}
                        error={formDataError}
                        type={"text"}
                        onChange={(e: any) => {
                          setNewKeyName(e.target.value);
                        }}
                        disabled={formData.planType === null}
                        height={"40px"}
                        maxLength={AppConstants.MAX_APIKEY_NAME_LENGTH}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-[13px] font-normal text-[#5E6E78] leading-10 mr-3">
                      Subscription Plan
                    </div>
                    <div
                      className={`max-w-[180px] w-full mr-5 ${
                        formDataError.planType && "-mt-[15px]"
                      }`}
                    >
                      <DropDown
                        name={"planType"}
                        label={""}
                        value={formData.planType}
                        error={formDataError.planType}
                        placeholder={"Search"}
                        onChange={(val: any) => {
                          const transformedVal = val;

                          if (transformedVal === "SELF_SERVE") {
                            setFormData({
                              ...formData,
                              planType: transformedVal,
                              period: null,
                              limit: null,
                              expiryDate: null,
                            });
                          } else {
                            setFormData({
                              ...formData,
                              planType: transformedVal,
                              expiryDate: new Date(),
                              period:
                                formData.limit === null ||
                                formData.limit === "" ||
                                formData.limit <= 0
                                  ? null
                                  : "MONTH",
                            });
                          }

                          setFormDataError({ ...formDataError, planType: "" });
                        }}
                        required={false}
                        options={["TRIAL", "ENTERPRISE", "SELF_SERVE"].map(
                          (option) => option
                        )}
                        className="!h-[40px] text-[#163545]"
                        disabled={
                          APIKeyData.status ===
                          AppConstants.SUBSCRIPTION_EXPIRED
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-[13px] font-normal text-[#5E6E78] leading-10 mr-3">
                      Issued
                    </div>
                    <div className="max-w-[150px] w-full mr-5">
                      <InputTextField
                        name={"creationDate"}
                        label={""}
                        value={{
                          creationDate: moment(formData.creationDate).format(
                            "MM/DD/YYYY"
                          ),
                        }}
                        error={{}}
                        type={"text"}
                        onChange={() => {}}
                        disabled={true}
                        height={"40px"}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-[13px] font-normal text-[#5E6E78] leading-10 mr-2 flex items-center">
                      Expiry{" "}
                      <Tooltip
                        title={"Date is in UTC"}
                        arrow
                        sx={{ width: "100px" }}
                      >
                        <span className="ml-[5px]">{InfoIcon}</span>
                      </Tooltip>
                    </div>
                    <div className="flex flex-col gap-2 2xl:flex-row">
                      <div className="max-w-[150px] w-full">
                        {isLoading && !formData.expiryDate ? (
                          ""
                        ) : (
                          <CalenderComp
                            value={formData.expiryDate || ""}
                            setValue={(val: any) => {
                              setFormData({
                                ...formData,
                                expiryDate: val,
                              });
                            }}
                            minDate={formData.creationDate || new Date()}
                          />
                        )}
                      </div>
                      <div className="flex">
                        <CheckBoxComponent
                          value={formData.expiryDate === null}
                          toggleCheck={() => {
                            setFormData({
                              ...formData,
                              expiryDate: formData.expiryDate
                                ? null
                                : keyData.expiryDate || new Date(),
                            });
                          }}
                          disabled={
                            formData.planType === "TRIAL" ||
                            formData.planType === null ||
                            formData.planType === "SELF_SERVE"
                          }
                        />
                        <div className="text-[13px] font-normal text-[#163545] leading-10 ml-2">
                          No End Date
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <APIKEY_LABEL
                    className="flex-1 min-w-fit"
                    onClick={() => {
                      if (expanded !== panel) {
                        editIndex !== index && setExpanded("");
                      }
                    }}
                  >
                    {apiKeyName || `Your Trial API Key ${index + 1}`}
                  </APIKEY_LABEL>
                  <div className="flex mr-[80px]" key={"plan"}>
                    <TextLabel className="mr-2">Subscription Plan:</TextLabel>
                    <TextValue>{planType}</TextValue>
                  </div>
                  <div className="flex" key={"quota"}>
                    <TextLabel className="mr-2">Issued:</TextLabel>
                    <TextValue>
                      {moment(creationDate).format("MM/DD/YYYY")}
                    </TextValue>
                  </div>

                  <div className="flex ml-8" key={"quota"}>
                    <TextLabel className="mr-2">Expiry:</TextLabel>
                    <TextValue>
                      {expiryDate
                        ? moment(expiryDate).format("MM/DD/YYYY")
                        : "No Expiry"}
                    </TextValue>
                  </div>
                </>
              )}
            </div>
          </div>
        }
        details={
          <div className="w-full pb-[70px] ml-[15px] mt-[15px]">
            <div className="w-full">
              <div className="mb-9 flex">
                <div className="max-w-[150px]">
                  <InputTextField
                    name={"qps"}
                    label={"QPS"}
                    value={formData}
                    error={formDataError}
                    type={"text"}
                    onChange={onChangeHandler}
                    disabled={
                      formData.planType === null ||
                      APIKeyData.status === AppConstants.SUBSCRIPTION_EXPIRED ||
                      formData.planType === "SELF_SERVE"
                    }
                    height={"40px"}
                  />
                </div>
                <div className="flex w-full flex-col 3xl:flex-row">
                  <div className="ml-6 mt-auto flex w-full">
                    <div>
                      <InputLabel className="flex-1 w-fit min-h-[16px]">
                        Quota
                      </InputLabel>

                      <div className="flex w-fit">
                        <div className="mr-5 flex">
                          <CheckBoxComponent
                            value={formData.limit === null}
                            toggleCheck={() => {
                              setFormData({
                                ...formData,
                                limit: null,
                                isRestricted: false,
                                period: null,
                              });
                            }}
                            disabled={
                              formData.planType === null ||
                              APIKeyData.status ===
                                AppConstants.SUBSCRIPTION_EXPIRED ||
                              formData.planType === "SELF_SERVE"
                            }
                            className="mt-auto mb-2"
                          />
                          <div className="text-[13px] font-normal text-[#163545] leading-10 ml-2 mt-auto">
                            Unlimited
                          </div>
                        </div>

                        <div className="mr-5 flex">
                          <CheckBoxComponent
                            value={formData.limit !== null}
                            toggleCheck={() => {
                              setFormData({
                                ...formData,
                                period: "MONTH",
                                isRestricted: true,
                                limit:
                                  APIKeyData.limit === null ||
                                  APIKeyData.limit === 0
                                    ? "10"
                                    : APIKeyData.limit.toString(),
                              });
                            }}
                            disabled={
                              formData.planType === null ||
                              APIKeyData.status ===
                                AppConstants.SUBSCRIPTION_EXPIRED ||
                              formData.planType === "SELF_SERVE"
                            }
                            className="mt-auto mb-2"
                          />
                          <div className="text-[13px] font-normal text-[#163545] leading-10 ml-2 mt-auto">
                            Restricted
                          </div>
                        </div>
                      </div>
                    </div>

                    {formData.limit !== null ? (
                      <>
                        <div className="max-w-[180px] w-full mr-2">
                          <DropDown
                            name={"period"}
                            label={" "}
                            value={formData.period}
                            error={""}
                            placeholder={"Search"}
                            onChange={(val: any) => {
                              const transformedVal = val;

                              setFormData({
                                ...formData,
                                period: transformedVal,
                                limit:
                                  APIKeyData.limit === null ||
                                  APIKeyData.limit === 0
                                    ? "10"
                                    : APIKeyData.limit.toString(),
                              });
                            }}
                            required={false}
                            options={["MONTH"].map((option) => option)}
                            className="!h-[40px] text-[#163545]"
                          />
                        </div>
                        <div className="max-w-[180px] w-full mr-2">
                          <InputTextField
                            name={"limit"}
                            label={" "}
                            value={formData}
                            error={formDataError}
                            type={"text"}
                            onChange={onChangeHandler}
                            disabled={
                              formData.planType === null ||
                              APIKeyData.status ===
                                AppConstants.SUBSCRIPTION_EXPIRED ||
                              formData.planType === "SELF_SERVE"
                            }
                            height={"40px"}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                      // <>
                      //   {formData.planType === "SELF_SERVE" ? (
                      //     <div className="max-w-[180px] w-full mr-2">
                      //       <DropDown
                      //         name={"period"}
                      //         label={" "}
                      //         value={formData.period}
                      //         error={""}
                      //         placeholder={"Search"}
                      //         onChange={(val: any) => {
                      //           if (val == "ANNUAL") {
                      //             setFormData({
                      //               ...formData,
                      //               period: val,
                      //               limit: null,
                      //             });
                      //           } else {
                      //             setFormData({
                      //               ...formData,
                      //               period: val,
                      //               limit:
                      //                 APIKeyData.limit === null ||
                      //                 APIKeyData.limit === 0
                      //                   ? "10"
                      //                   : APIKeyData.limit.toString(),
                      //             });
                      //           }
                      //         }}
                      //         required={false}
                      //         options={
                      //           formData.planType === "SELF_SERVE"
                      //             ? ["MONTH", "ANNUAL"]
                      //             : ["MONTH"]
                      //         }
                      //         className="!h-[40px] text-[#163545]"
                      //         disabled={formData.planType === "SELF_SERVE"}
                      //       />
                      //     </div>
                      //   ) : (
                      //     <></>
                      //   )}
                      // </>
                    )}
                  </div>
                  {/* {APIKeyData.planType === "SELF_SERVE" && (
                    <div className="max-w-[180px] w-full ml-6 mt-auto h-fit-content 3xl:ml-0">
                      <TrestleButton
                        onClick={() => {
                          UpdateSubscription(true);
                        }}
                        label="Renew Subscription"
                        type="primary"
                        className="!w-fit-content ml-auto !h-[40px]"
                      />
                    </div>
                  )} */}
                </div>
              </div>

              <div className="flex items-center space-x-3">
                <div className="flex-grow">
                  <InputTextField
                    name={"apikey"}
                    label={"API Key"}
                    value={{ apikey: apiKey }}
                    error={{
                      apikey:
                        APIKeyData.status === AppConstants.SUBSCRIPTION_EXPIRED
                          ? " "
                          : "",
                    }}
                    type={"text"}
                    onChange={() => {}}
                    disabled={true}
                    copyToClipboard={true}
                  />
                </div>
                <div className="ml-3 mt-auto">
                  <TrestleButton
                    onClick={() => {
                      setOpenPopup(true);
                    }}
                    label="Revoke Key"
                    type="primary"
                    className="!min-w-[145px] !bg-[#F8F1F1] !text-[#B23352] !disabled:bg-[#F8F1F1] disabled:opacity-40"
                    disabled={!subscriptionId || updatable}
                  />
                </div>
              </div>
              {APIKeyData.status === AppConstants.SUBSCRIPTION_EXPIRED && (
                <InputErrortext className="flex items-center mt-1">
                  <img
                    src={Alerttriangle}
                    alt="required"
                    style={{ marginRight: "4px" }}
                  />
                  {"This API key has expired."}
                </InputErrortext>
              )}
              <div className="mt-10">
                <TrestleTable
                  isPaginated={false}
                  headCells={headCells1}
                  rows={
                    productsData.map((item: any) => {
                      return {
                        ...item,
                        subscriptionId: APIKeyData.subscriptionId,
                      };
                    }) || []
                  }
                />
                {planType === "SELF_SERVE" ? (
                  <>
                    <div className="flex w-[100%] pr-[45px] justify-end text-base font-medium text-[#163545]">
                      <div className="text-right pr-[40px]">Total:</div>
                      <div className="text-center">{totalUsageCount}</div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              {true ? (
                <CustomAttributes
                  expanded={expanded}
                  customAttributesData={customAttributes}
                  setCustomAttributesData={(val: any) => {
                    setCustomAttributes(val);
                  }}
                  customAttributesDropdownValues={
                    customAttributesDropdownValues
                  }
                  customDropError={customDropError}
                  setCustomDropError={setCustomDropError}
                  disable={
                    APIKeyData.status === AppConstants.SUBSCRIPTION_EXPIRED
                  }
                  availableProductOptions={availableProductOptions}
                />
              ) : (
                <></>
              )}

              {
                <MaxioSubscriptionDetails
                  availableProductOptions={availableProductOptions}
                  maxioSubscriptionsData={maxioSubscriptions}
                  setMaxioSubscriptionsData={(val: any) => {
                    setMaxioSubscriptions(val);
                  }}
                  disable={
                    APIKeyData.status === AppConstants.SUBSCRIPTION_EXPIRED
                  }
                  userData={userData}
                />
              }
            </div>
          </div>
        }
      />

      <WaringPopup
        open={openPopup}
        close={() => {
          setOpenPopup(false);
        }}
        title={updatable ? "Changes" : "Revoke API Key"}
        text={
          updatable
            ? "Are you sure you want to close without saving changes?"
            : "Are you sure want to revoke this API key?"
        }
        buttonLable={updatable ? "Yes, close without saving" : "Yes"}
        onAction={() => {
          setOpenPopup(false);
          if (updatable) {
            setDataToFormData();
            setEditIndex(null);
          } else {
            RevokeAPIKey(subscriptionId);
          }
        }}
      />

      {updatable && !isLoading && (
        <SaveChangesBtnPopup className="w-full p-10 bg-white">
          <div className="flex">
            <TrestleButton
              onClick={() => {
                setOpenPopup(true);
              }}
              label="Cancel and Revert Changes"
              type="secondry"
              className="flex-1 !max-w-[250px]"
            />
            <TrestleButton
              onClick={() => {
                UpdateSubscription();
              }}
              label="Save Changes"
              type="primary"
              className="!w-[250px] ml-auto"
            />
          </div>
        </SaveChangesBtnPopup>
      )}
    </>
  );
}

export default APIAccordionItem;

export const SaveChangesBtnPopup = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  margin-left: -40px;
  width: 75%;
  box-shadow: 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
`;
