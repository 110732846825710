import React from "react";
import { isEmpty } from "lodash";
import { Navigate } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";

function HomeComponent() {
  const userMode = sessionStorage.getItem("userMode");

  return (
    <>
      {
        (!isEmpty(userMode) && userMode === "ADMIN") ?
          <Navigate to={`${RouteConstants.ROUTE_ADMIN_HOME}`} />
          : <Navigate to={`${RouteConstants.ROUTE_HOME}`} />
      }
    </>
  )
}

export default HomeComponent;