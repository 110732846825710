import { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import InputTextField from "./InputTextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";

interface DateRangeTrestleProps {
  value: any;
  setValue: any;
}

function DateRangeTrestle({ value, setValue }: DateRangeTrestleProps) {
  const [selectionRange, setSelectionRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const { startDate = new Date(), endDate = new Date() } = value;

    setSelectionRange([
      {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
      },
    ]);
  }, []);

  const [selectedData, setSelectedDate] = useState<string>("");

  const handleSelect = (ranges: any) => {
    const { startDate = new Date(), endDate = new Date() } = ranges.selection;
    setSelectionRange([ranges.selection]);

    setSelectedDate(
      `${moment(startDate).format("DD/MM/YYYY")} - ${moment(endDate).format(
        "DD/MM/YYYY"
      )}`
    );

    setValue({ startDate, endDate });
  };

  const selectionRangeD = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="!cursor-pointer"
      >
        <InputTextField
          name={"date"}
          label={"Custom Date Range"}
          placeholder="DD/MM/YYYY - DD/MM/YYYY"
          value={{ date: selectedData }}
          error={{}}
          type={"text"}
          onChange={() => {}}
          disabled={false}
          height="50px"
          isCalendar={true}
        />
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="mt-2"
      >
        {/* <MenuItem> */}
        <DateRangePicker
          ranges={selectionRange}
          onChange={handleSelect}
          months={1}
          showDateDisplay={false}
        />
        {/* </MenuItem> */}
      </Menu>
    </>
  );
}

export default DateRangeTrestle;
