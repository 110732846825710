import React from "react";
import { Box, Stack, Tooltip } from "@mui/material";
import ProductDetailsCard from "./ProductDetailsCard";
import ProductPricingDetailsCard from "./ProductPricingDetailsCard";
import { Text16pxBold } from "./CommonComponents";
import { ConstantTexts } from "../../constants/ConstantTexts";
import { InfoIcon } from "../../assets/svgs";

interface IProductCategoryDetailsCardProps {
  productCategoryName: string;
  productCategoryAPIList: [
    {
      apiName: string;
      apiPrice: string;
    }
  ];
}

const ProductCategoryDetailsCardComponent = ({
  productCategoryName = "",
  productCategoryAPIList,
}: IProductCategoryDetailsCardProps) => {
  const getTooltipText = (text: string) => {
    switch (text) {
      case "Validation & Verification APIs":
        return ConstantTexts.VALIDATION_VERIFICATION_API_TOOLTIP;
      case "Enrichment APIs":
        return ConstantTexts.ENRICHMENT_API_TOOLTIP;
      case "Telephony APIs":
        return ConstantTexts.TELEPHONY_API_TOOLTIP;
      default:
        return "";
    }
  };
  return (
    <Stack direction={"column"} spacing={2}>
      <Box
        sx={{
          borderBottom: "1px solid #163545",
          fontSize: "20px",
          fontWeight: "500",
          color: "#163545",
        }}
      >
        {productCategoryName && (
          <Box display={"flex"}>
            <Text16pxBold c="#163545">{productCategoryName}</Text16pxBold>
            <Tooltip
              title={getTooltipText(productCategoryName)}
              arrow
              sx={{ width: "100px" }}
            >
              <span className="ml-[5px]">{InfoIcon}</span>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Stack direction={"column"} spacing={"4px"}>
        {productCategoryAPIList.map((item, index) => (
          <ProductPricingDetailsCard
            productName={item?.apiName}
            productPrice={item?.apiPrice}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default ProductCategoryDetailsCardComponent;
