import React from "react";
import { ArrowDownIcon, ArrowUpIcon } from "../../assets/svgs";
import { TextValue } from "./CommonComponents";
interface TestRequestComponentProps {
  status: string;
  rowEle: any;
  currentEle: any;
  handleClick: (displayText?: string) => void;
  disableDropDown: boolean;
  setDisableDropDown: React.Dispatch<React.SetStateAction<boolean>>;
}

const TestRequestComponent = ({
  status = "",
  rowEle = {},
  currentEle = {},
  handleClick = () => {},
  disableDropDown,
  setDisableDropDown,
}: TestRequestComponentProps) => {
  const getDisplayText = () => {
    if (rowEle.subscriptionStatus) {
      switch (status) {
        case "AVAILABLE":
          return "Make Test Request";
        default:
          return "Try for Free";
      }
    } else {
      return "Try for Free";
    }
  };

  const scrollToSmoothly = (elementId: any) => {
    var element = document.getElementById(elementId);

    setTimeout(() => {
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 5);
  };

  return (
    <div className="testRequestContainer cursor-pointer" onClick={()=> rowEle.usagePlanId === currentEle.usagePlanId ?  handleClick() : ( scrollToSmoothly(rowEle.usagePlanId),handleClick(getDisplayText()))}>
      <TextValue style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
        {getDisplayText()}
      </TextValue>
      {rowEle.usagePlanId === currentEle.usagePlanId ? (
        <div
          className="w-[40px] h-[40px]  flex justify-center items-center ml-auto">
          {ArrowUpIcon}
        </div>
      ) : (
        <div
          id={`${rowEle.usagePlanId}`}
          className="w-[40px] h-[40px] flex justify-center items-center ml-auto" >
          {ArrowDownIcon}
        </div>
      )}
    </div>
  );
};

export default TestRequestComponent;
