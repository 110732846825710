import React, { useEffect, useState } from "react";
import { UpdateUserStatus, getAllUsers } from "../../../http/admin-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import TrestleTable, {
  TableHeaderProps,
} from "../../Shared/TrestleTable/TrestleTable";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import moment from "moment";
import InputTextField from "../../Shared/InputTextField";
import LoaderComponent from "../../Shared/LoaderComponent";
import {
  ComponentLabelHeader,
  WalletBalanceText,
} from "../../Shared/CommonComponents";
import DropDown from "../../Shared/DropDown";
import NoDataFound from "../../Shared/NoDataFound";
import _, { find } from "lodash";
import UserDrawer from "../UserDrawer/UserDrawer";
import TrestleButton from "../../Shared/TrestleButton";
import { getUTCDate } from "../../../utils/helpers";
import { Box, Tooltip, Typography } from "@mui/material";
import { InfoIcon } from "../../../assets/svgs";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { useAppSelector } from "../../../app/hooks";
import TeamDetailsDrawer from "../../Teams/TeamDetails/teamDetails";
import AddNewUserPopUp from "../AddNewUser/addNewUserPopUp";
import DialogPopup from "../../Shared/DialogPopup";
import { CreateManageSubscriptionSession } from "../../Shared/PaymentWallet/Services/PaymentWalletServices";
import { Helmet } from "react-helmet";

export interface IUsers {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  createdAt: Date;
}

const AllUsers = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [users, setUsers] = useState<IUsers[]>([]);
  const [usersBack, setUsersBack] = useState<IUsers[]>([]);

  const [pageNumber, setPageNumber] = useState(0);

  const [noOfRecords, setNoOfRecords] = useState(20);

  const [totalRecords, setTotalRecords] = useState(0);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [searchData, setSearchData] = useState<any>({
    searchString: "",
    searchKey: "Name",
  });

  const [isDisableUserOpen, setIsDisableUserOpen] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>([]);
  const dropDownValues = [
    { label: "API Key", key: "apiKey" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Company", key: "company" },
    // { label: "Team", key: "team" },
    { label: "Product Name", key: "productName" },
    { label: "Plan Type", key: "planType" },
    { label: "Plan Name", key: "planName" },
  ];

  const dispatch = useDispatch();

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  useEffect(() => {
    window.SetMode("ADMIN");
    handleMixPanel(MixPanelEvents.VISIT_ADMIN_ALL_USERS, {
      userId: userData?.id,
      userEmail: userData?.email,
    });
  }, []);

  useEffect(() => {
    let reqData: any = { pageNumber: pageNumber, noOfRecords: noOfRecords };
    if (searchData.searchKey != "" && searchData.searchString != "") {
      let searchKeyObj: any = find(dropDownValues, {
        label: searchData.searchKey,
      });

      reqData[searchKeyObj.key] = searchData.searchString;

      const callApiTimeout = setTimeout(() => {
        getAllUsersApiCall(reqData);
      }, 1000);

      return () => clearTimeout(callApiTimeout);
    } else {
      getAllUsersApiCall(reqData);
    }
  }, [pageNumber, noOfRecords, searchData]);

  const getAllUsersApiCall = async (requestData: any) => {
    setLoading(true);
    const response = await getAllUsers(requestData);
    setLoading(false);

    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setTotalRecords(data.data.totalRecords);
      setUsers(formatUserList(data.data.users));
    } else {
      const { details } = data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  const formatUserList = (users: any[]) => {
    let temp: any = users.map((user: any) => {
      const { company = null, name: teamName = "-" } = user?.team || {};
      const { name = "-" } = company || {};
      return { ...user, team: teamName, company: name, teamData: user?.team };
    });

    return temp;
  };

  useEffect(() => {
    setUsersBack(users);
  }, [users]);

  const handleChangeUserStatusClicked = (ele: any) => {
    setIsDisableUserOpen(true);
    setCurrentUser(ele);
  };
  const headCells: readonly TableHeaderProps[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "User Id",
      width: "5%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      width: "10%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <UserDrawer
            userName={`${ele.firstName || "-"} ${ele.lastName || ""}`}
            userData={ele}
          />
        );
      },
      sortable: false,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      width: "15%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      label: "Company",
      width: "10%",
      isCustomElements: false,
      customElements: (ele: any) => {
        const { company = null } = ele?.team || {};
        const { name = "-" } = company || {};
        return `${name}`;
      },
      sortable: true,
    },
    {
      id: "entityStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
      width: "10%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: (
        <div className="flex">
          <div>Access</div>
          <Tooltip title={"Date is in UTC"} arrow sx={{ width: "100px" }}>
            <span className="ml-[5px]">{InfoIcon}</span>
          </Tooltip>
        </div>
      ),
      width: "10%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <span>
            {moment(getUTCDate(ele.createdAt || "")).format("MM/DD/YYYY")}
          </span>
        );
      },
      sortable: true,
    },
    // {
    //   id: "",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "",
    //   width: "8%",
    //   isCustomElements: true,
    //   customElements: (ele: any) => {
    //     return(
    //       <>
    //       {ele.entityStatus == "ACTIVE"?<TrestleButton
    //       onClick={() => handleChangeUserStatusClicked(ele)}
    //       label="Deactivate"
    //       // disabled={disabledSubmit()}
    //       type="error"
    //       className="!h-[40px] !min-w-[fit-content] !px-[15px]"
    //     />:
    //     <TrestleButton
    //         onClick={() => handleChangeUserStatusClicked(ele)}
    //         label="Reactivate"
    //         // disabled={disabledSubmit()}
    //         type="primary"
    //         className="!h-[40px] !min-w-[fit-content] !px-[15px]"
    //       />}
    //     </>)},
    //   sortable: false,
    // },
  ];

  const manageSubscriptionApiCall = async (email: string) => {
    setLoading(true);
    const response = await CreateManageSubscriptionSession(email);
    const { statusCode = 401, data = {} } = response;
    if (statusCode === 200) {
      window.open(data, "_self");
    } else {
      setLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: "Something Went Wrong",
        })
      );
    }
  };

  const onInputValueChange = (ele: any) => {
    const { value, name } = ele.target;
    setSearchData({ ...searchData, [name]: value });
    if (name === "searchString") {
      OnSearchChange(value);
    }
  };

  const changeUserStatus = async () => {
    let reqData: any = { pageNumber: pageNumber, noOfRecords: noOfRecords };
    const payload = {
      user_id: currentUser.id,
      new_user_status:
        currentUser.entityStatus === "ACTIVE" ? "DEACTIVATED" : "ACTIVE",
    };
    const response = await UpdateUserStatus(payload);
    const { status = 401, data } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setIsDisableUserOpen(false);
      getAllUsersApiCall(reqData);
      setSearchData({
        searchString: "",
        searchKey: "Name",
      });
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg:
            data?.data?.entityStatus === "ACTIVE"
              ? "User is reactivated successfully"
              : "User is deactivated successfully",
        })
      );
    }
  };

  const OnSearchChange = (str: string) => {};

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trestle Developer Portal</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DEV_PORTAL_URL}admin/users`}
        />
      </Helmet>
      <LoaderComponent loader={loading} />
      <div className="pr-[50px]">
        <div className="flex pl-[50px] mb-[30px]">
          <ComponentLabelHeader className="flex-1">
            Manage Users
          </ComponentLabelHeader>
        </div>
      </div>
      <div className="pr-[50px] min-h-full bg-white">
        <div className="px-[50px]  h-full">
          <div className="flex py-[45px] justify-between">
            <div className="text-[20px] font-medium text-[#163545] leading-[50px]">
              Users
            </div>
            <div className="flex w-full gap-[10px] justify-end">
              <div className="max-w-[180px] w-full">
                <DropDown
                  name={"searchKey"}
                  label={""}
                  value={searchData["searchKey"]}
                  error={""}
                  placeholder={"Search"}
                  onChange={(val: any) => {
                    setSearchData({
                      ...searchData,
                      searchKey: val,
                      searchString: "",
                    });
                  }}
                  required={false}
                  options={dropDownValues.map((key) => key.label)}
                  className="!h-[50px] text-[#163545]"
                />
              </div>
              <div className="max-w-[400px] w-full">
                <InputTextField
                  name={"searchString"}
                  label={""}
                  placeholder="Search"
                  value={searchData}
                  error={{}}
                  type={"text"}
                  onChange={onInputValueChange}
                  required={false}
                  maxLength={AppConstants.MAX_NAME_LENGTH}
                  height="50px"
                  searchable={true}
                />
              </div>
              <div className="w-[200px]">
                <TrestleButton
                  onClick={() => setIsOpen(true)}
                  label="Add New User"
                  // disabled={disabledSubmit()}
                  type="primary"
                  className="!h-[50px]"
                />
              </div>
            </div>
          </div>
          {users.length > 0 ? (
            <TrestleTable
              isPaginated={true}
              headCells={headCells}
              rows={usersBack}
              setPageNumber={setPageNumber}
              totalNoOfRecords={totalRecords}
              setNoOfRecords={setNoOfRecords}
            />
          ) : (
            !loading && <NoDataFound className="mt-52" />
          )}
        </div>
      </div>
      <AddNewUserPopUp
        open={isOpen}
        setPopup={setIsOpen}
        setIsLoading={setLoading}
      />
      <DialogPopup
        open={isDisableUserOpen}
        onClose={() => setIsDisableUserOpen(false)}
        className="!p-0"
        component={
          <>
            <div className="w-full py-8 px-10 bg-[#F3F5F6] text-[#163545] mb-6">
              {currentUser.entityStatus == "ACTIVE" ? (
                <div className="text-xl font-bold flex">
                  {/* <span className="mr-3">{TeamsIcon}</span> */}
                  Deactivate User
                </div>
              ) : (
                <div className="text-xl font-bold flex">
                  {/* <span className="mr-3">{TeamsIcon}</span> */}
                  Reactivate User
                </div>
              )}
            </div>
            {currentUser.entityStatus === "ACTIVE" ? (
              <Typography className="flex mt-[20px] py-8 px-10">
                Are you sure you want to deactivate this user? Once you
                deactivate this user, it will not be able to login to the
                portal.
              </Typography>
            ) : (
              <Typography className="flex mt-[20px] py-8 px-10">
                Are you sure you want to reactivate this user? Once you
                reactivate this user, it will be able to login with it's
                existing credentials and access the api key and other details.
              </Typography>
            )}

            <Box className="flex gap-[10px] mt-[20px] py-8 px-10">
              {currentUser.entityStatus === "ACTIVE" ? (
                <TrestleButton
                  onClick={() => changeUserStatus()}
                  label="Deactivate User"
                  // disabled={disabledSubmit()}
                  type="error"
                  className="!h-[40px]!min-w-[fit-content]"
                />
              ) : (
                <TrestleButton
                  onClick={() => changeUserStatus()}
                  label="Reactivate User"
                  // disabled={disabledSubmit()}
                  type="primary"
                  className="!h-[40px]!min-w-[fit-content]"
                />
              )}
              <TrestleButton
                onClick={() => {
                  setIsDisableUserOpen(false);
                }}
                label="Cancel"
                // disabled={disabledSubmit()}
                type="secondry"
                className="!h-[40px]!min-w-[fit-content]"
              />
            </Box>
          </>
        }
      />
    </>
  );
};

export default AllUsers;
