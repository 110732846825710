import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { ClosePopup } from "../../assets/svgs";

interface dialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  component: any;
  closeButton?: boolean;
  title?: string;
  closeOnOut?: boolean;
  className?: string;
  dialogStyleClass?: string;
}

export default function DialogPopup({
  open,
  onClose,
  component,
  closeButton = false,
  title = "",
  closeOnOut = false,
  className = "",
  dialogStyleClass = "",
}: dialogProps) {
  return (
    <Dialog
      sx={{
        zIndex: "111111 !important",
      }}
      onClose={() => {
        if (closeOnOut) onClose(false);
      }}
      open={open}
      className={`overflow-hidden w-full ${dialogStyleClass}`}
    >
      {title && (
        <DialogTitle className="flex">
          <div className="font-bold text-2xl">{title}</div>
          <div
            className="w-fit ml-auto cursor-pointer"
            onClick={() => {
              onClose(false);
            }}
          >
            {ClosePopup}
          </div>
        </DialogTitle>
      )}
      <DialogContent className={`${className || "!p-7 !pt-2"}`}>
        <div>{component}</div>
      </DialogContent>
    </Dialog>
  );
}
