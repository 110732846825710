export class RouteConstants {
  static readonly ROUTE_LOGIN = "/login";
  static readonly ROUTE_SIGNUP = "/signup";
  static readonly ROUTE_VERIFY = "/verification";
  static readonly ROUTE_RESET_PASSWORD = "/reset-password";
  static readonly ROUTE_ALREADY_ACCOUNT = "/account-exist";
  static readonly ROUTE_CONFIRM_RESET_PASSWORD = "/confirm-reset-password";
  static readonly ROUTE_FORCE_CHANGE_PASSWORD = "/change-password";

  // user routes
  static readonly ROUTE_YOUR_USAGE = "/usage";
  static readonly ROUTE_YOUR_USAGE_V2 = "/usage_v2";
  static readonly ROUTE_YOUR_API_KEY = "/api-keys";
  static readonly ROUTE_PRICING_PAGE = "/pricing";
  static readonly ROUTE_HOME = this.ROUTE_YOUR_API_KEY;

  // admin routes
  static readonly ROUTE_ADMIN_PRODUCT_ACCESS = "/admin/product-access";
  static readonly ROUTE_ADMIN_ALL_USERS = "/admin/users";
  static readonly ROUTE_ADMIN_USAGES = "/admin/usages";
  static readonly ROUTE_ADMIN_HOME = this.ROUTE_ADMIN_ALL_USERS;
  static readonly ROUTE_ADMIN_TEAMS = "/admin/teams";

  // home routes
  static readonly ROUTE_DUMMY_HOME = "/";
}
