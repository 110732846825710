import React from "react";
import BaseAPIClient from "../../../../http/api";
import { AppConstants } from "../../../../constants/AppConstants";

const globalHeaders: any = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const API_BASE_URL: string =
  process.env.REACT_APP_API_URL || "http://localhost";
const API = BaseAPIClient?.axiosInstance;

interface IWalletData {
  amount?: string | number | null;
  targetBalance?: string | number | null;
  balanceThreshold?: string | number | null;
  isAutoRechargeEnabled?: boolean | null;
}

export const GetWalletData = async (email: string = "") => {
  const header = { ...globalHeaders };
  const uri: string = `${API_BASE_URL}${AppConstants.GET_WALLET_DATA.replace(
    "{{email}}",
    email
  )}`;
  const response = await API.get(uri, { headers: header });
  return response.data;
};

export const CreateCheckoutSession = async (data: IWalletData) => {
  const header = {
    ...globalHeaders,
  };
  const uri: string = `${API_BASE_URL}${AppConstants.WALLET_CHECKOUT_SESSION}`;
  const response = await API.post(uri, data, { headers: header });
  return response.data;
};

export const UpdateWalletData = async (data: IWalletData) => {
  const header = {
    ...globalHeaders,
  };
  const uri: string = `${API_BASE_URL}${AppConstants.UPDATE_AUTO_RECHARGE_INFO}`;
  const response = await API.post(uri, data, { headers: header });
  return response.data;
};

export const CreateManageSubscriptionSession = async (email?: string) => {
  const header = {
    ...globalHeaders,
  };
  const uri: string = `${API_BASE_URL}${
    AppConstants.MANAGE_SUBSCRIPTION_SESSION
  }?email=${email ? email.replace("+", "%2b") : ""}`;
  const response = await API.get(uri, { headers: header });
  return response.data;
};

export const UpdatemonthlyTransactionLimit = async (email: string, limit: string) => {
  const header = {
    ...globalHeaders,
  };
  const uri: string = `${API_BASE_URL}${AppConstants.UPDATE_MONTHLY_TXN_LIMIT}?email=${email ? email.replace("+", "%2b") : ""}&limit=${limit}`;
  console.log(uri);
  const response = await API.post(uri, { headers: header });
  console.log(response.data);
  return response.data;
};
