import React, { useEffect, useState } from "react";
import TrestleButton from "../../Shared/TrestleButton";
import DialogPopup from "../../Shared/DialogPopup";
import Select from "react-select";
import "./react-select.css";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import { getAllUsers } from "../../../http/admin-service";
import { useAppDispatch } from "../../../app/hooks";
import { createTeamInvitation } from "../../../http/teams-service";
import SingUpUserForm from "../../Shared/SignupForm";
import { addNewUser } from "../../../http/authentication-service";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { ConstantTexts } from "../../../constants/ConstantTexts";

interface AddMoreUserToTeamProps {
  open: boolean;
  setPopup: any;
  setIsLoading?: any;
  teamData?: any;
}

export default function AddMoreUserToTeam({
  open,
  setPopup,
  setIsLoading = () => {},
  teamData = {},
}: AddMoreUserToTeamProps) {
  const dispatch = useAppDispatch();

  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [userList, setUserList] = useState<any>([
    {
      label: "Create New User",
      value: "Create New User",
      id: "Create New User",
    },
  ]);

  useEffect(() => {
    setSelectedUsers([]);
    getAllUsersApiCall();
    handleMixPanel(MixPanelEvents.INVITE_NEW_TEAM_MEMBER, {});
  }, []);

  const getAllUsersApiCall = async () => {
    setIsLoading(true);
    const response = await getAllUsers({
      pageNumber: AppConstants.DEFAULT_PAGE_NUMBER,
      noOfRecords: AppConstants.MAX_PAGE_SIZE,
    });
    setIsLoading(false);

    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setUserList((prevSate: any) => [
        ...prevSate,
        ...formateUserListForReactSelect(data.data.users),
      ]);
    } else {
      const { details } = data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  const formateUserListForReactSelect = (arr: any) => {
    let temp: any = [];
    arr.map((user: any) => {
      if (user.team.id !== teamData.teamId) {
        temp.push({ label: user.email, value: user.email, id: user.id });
      }
    });

    return temp;
  };

  const CreateTeamInvitation = async () => {
    setIsLoading(true);
    const { teamId = "", companyId = "", userId = "" } = teamData || {};

    const payload: any = {
      requester_user_id: userId,
      company_id: companyId,
      team_id: teamId,
      invitee_ids_list: [selectedUsers.id],
    };

    const response = await createTeamInvitation(payload);
    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: invitation = null } = data || {};
      setIsLoading(false);

      if (invitation[selectedUsers.id] === "Success") {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: "Team Invitation Created Successfully",
          })
        );
        setPopup(false);
      } else {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: invitation[selectedUsers.id],
          })
        );
      }
      // setPopup(false);
    } else {
      const { details, message } = data;
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: message,
        })
      );
    }
  };

  const inviteNewUser = async (
    payload: any,
    formError: any,
    setFormError: any
  ) => {
    const { teamId = "" } = teamData || {};
    const newPayload: any = { ...payload, teamId: teamId };

    const response = await addNewUser(newPayload);
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg: ConstantTexts.TEAM_INVITATION_SUCCESS,
        })
      );
      handleMixPanel(MixPanelEvents.TEAM_INVITE_SENT_SUCCESSFULLY, {
        email: payload.email,
      });
      setPopup(false);
    } else {
      const { error = "", details = "" } = data || {};
      if (details === "Email is Invalid") {
        setFormError({ ...formError, email: AppConstants.EMAIL_ERROR_MSG });
      } else {
        dispatch(
          setToast({
            open: true,
            severity: "error",
            msg: details,
          })
        );
        if (details === "User with same email already exists") {
          dispatch(
            setToast({
              open: true,
              severity: "error",
              msg: details,
            })
          );
        }
      }

      if (error === "Email is Invalid") {
        handleMixPanel(MixPanelEvents.ADMIN_ENTERED_INVALID_EMAIL, {
          email: payload.email,
          error: error,
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <DialogPopup
      open={!!open}
      onClose={(val: any) => {
        setPopup(true);
      }}
      closeButton={true}
      dialogStyleClass="dialogInvitation"
      title={""}
      className="!p-0"
      component={
        <div>
          <div className="w-full py-8 px-10 bg-[#F3F5F6] text-[#163545] mb-6">
            <div className="text-xl font-bold flex">
              {/* <span className="mr-3">{TeamsIcon}</span> */}
              Add Team Member
            </div>
          </div>
          <div className="p-10 pt-0 w-full text-lg">
            <div>
              {open && (
                <div className="mb-10">
                  Please select user from dropdown to invite in your team.
                </div>
              )}
            </div>

            <div>
              <div className="w-full mr-2">
                <Select
                  options={userList}
                  placeholder="Select User"
                  value={selectedUsers}
                  onChange={(e: any) => {
                    setSelectedUsers(e);
                  }}
                  className="select-dropdown"
                />
              </div>
            </div>
            {selectedUsers?.value === "Create New User" && (
              <SingUpUserForm
                type={"ADD_NEW_TEAM_MEMBER"}
                setPopup={setPopup}
                setIsLoading={setIsLoading}
                SignUpFinalCall={inviteNewUser}
                selectedUsers={selectedUsers}
                teamData={teamData}
              />
            )}
            {!(selectedUsers?.value === "Create New User") && (
              <>
                <div className={"mt-40 mb-8 text-base"}>
                  {ConstantTexts.TEAM_INVITE_MASSAGE}
                </div>
                <div className="flex">
                  <TrestleButton
                    onClick={CreateTeamInvitation}
                    label="Invite"
                    type="primary"
                    className="max-w-[180px] !h-[40px] !py-0 !pt-0 ml-auto mr-auto"
                    disabled={selectedUsers.length === 0}
                  />
                  <TrestleButton
                    onClick={() => {
                      setPopup(false);
                    }}
                    label="Cancel"
                    type="secondry"
                    className="max-w-[180px] !h-[40px] !py-0 !pt-0 mr-auto"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      }
      closeOnOut={true}
    />
  );
}
