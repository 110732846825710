import React from "react";
import axios, { AxiosInstance } from "axios";

interface MakeApiServiceCallProps {
  row: any;
  apiKey: string;
  formData: any;
}

// const API = new BaseClient().axiosInstance;

export const MakeApiServiceCall = async ({
  row,
  apiKey = "",
  formData,
}: MakeApiServiceCallProps) => {
  const baseUrl = `${process.env.REACT_APP_TRESTLE_API_URL}`;
  const GetApiEndpoint = () => {
    switch (row?.apiName) {
      case "Caller Identification 3.1":
        return `${baseUrl}/3.1/caller_id?phone=${formData.phone}`;
      case "Caller Identification 3.0":
        return `${baseUrl}/3.0/caller_id?phone=${formData.phone}`;
      case "Phone Intel 3.0":
        return `${baseUrl}/3.0/phone_intel?phone=${formData.phone}`;
      case "Real Contact 1.1":
        return `${baseUrl}/1.1/real_contact?phone=${formData.phone}&name=${formData.productName}&email=${formData.email}`;
      case "Real Contact 1.0":
        return `${baseUrl}/1.0/real_contact?phone=${formData.phone}&name=${formData.productName}&email=${formData.email}`;
      case "Reverse Phone 3.2":
        return `${baseUrl}/3.2/phone?phone=${formData.phone}`;
      case "Reverse Phone 3.1":
        return `${baseUrl}/3.1/phone?phone=${formData.phone}`;
      case "Reverse Phone 3.0":
        return `${baseUrl}/3.0/phone?phone=${formData.phone}`;
      case "Smart CNAM 3.1":
        return `${baseUrl}/3.1/cnam?phone=${formData.phone}`;
      case "Phone Data 1.0":
        return `${baseUrl}/1.0/phone_data?phone=${formData.phone}`;
      case "Reverse Address 3.0":
        return `${baseUrl}/3.0/location?${generateReverseAddressQuery(formData)}`;
      case "Reverse Address 3.1":
        return `${baseUrl}/3.1/location?${generateReverseAddressQuery(formData)}`;
      case "Find Person 3.0":
        return `${baseUrl}/3.0/person?${generateFindPersonQuery(formData)}`;
      case "Find Person 3.1":
        return `${baseUrl}/3.1/person?${generateFindPersonQuery(formData)}`;
      case "Location Intel 3.0":
        return `${baseUrl}/3.0/location_intel?${generateReverseAddressQuery(formData)}`;  
    }
  };

  const checkInput = (data:any)=>{
    if(data==null || data==undefined)
      return "";
    else
      return data;
  };

  const generateFindPersonQuery = (formData:any)=>{
    let query="";
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) { 
          if(formData[key]!==null && formData[key]!==undefined && formData[key].trim() !=='')
          {
            if(key==='productName')
              query+=("name="+formData[key]+"&");
            else
              query+=("address."+key+"="+formData[key]+"&");
          }
      }
    }
    return query;
  }

  const generateReverseAddressQuery = (formData:any)=>{
    let query="";
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) { 
          if(formData[key]!==null && formData[key]!==undefined && formData[key].trim() !=='')
          {
              query+=(key+"="+formData[key]+"&");
          }
      }
    }
    return query;
  }

  const url = GetApiEndpoint();
  const headers = {
    Accept: "application/json",
    "x-api-key": `${apiKey}`,
    // "Access-Control-Allow-Headers": "*",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "*",
    // "Cache-Control": "no-store, no-cache",
    // Pragma: "no-cache",
    // Expires: 0,
    // "X-Content-Type-Options": "nosniff",
    // "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
    // "X-Frame-Options": "SAMEORIGIN",
  };
  return axios.get(`${url}`, { headers });
};
