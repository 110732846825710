import React, { useEffect, useState } from "react";
import DropDown from "../Shared/DropDown";
import TrestleButton from "../Shared/TrestleButton";
import TrestleTable, { TableHeaderProps } from "../Shared/TrestleTable/TrestleTable";
import { AlertTrianleIcon, DeleteItemCross, SearchIcon } from "../../assets/svgs";
import InputTextField, { InputErrortext } from "../Shared/InputTextField";
import FindMaxioSubscriptionIDs from "./findMaxioSubscriptionsIDs";
import { setToast } from "../../app/toastReducer";
import { useDispatch } from "react-redux";

interface MaxioSubscriptionDetailsProps {
  maxioSubscriptionsData: any;
  setMaxioSubscriptionsData: any;
  disable?: boolean;
  userData: any;
  availableProductOptions: any;
}

function MaxioSubscriptionDetails({
  maxioSubscriptionsData,
  setMaxioSubscriptionsData,
  disable = false,
  userData,
  availableProductOptions,
}: MaxioSubscriptionDetailsProps) {
  const [selectedAttribute, setselectedAttribute] = useState<any>("");
  const [maxioSubscriptions, setMaxioSubscriptions] = useState<any>([
    ...maxioSubscriptionsData,
  ]);


  const [selectMaxioSubscriptionIDs, setSelectMaxioSubscriptionIDs] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<any>(null);

  const dispatch = useDispatch();

  const headCellsAttributes: readonly TableHeaderProps[] = [
    {
      id: "productName",
      numeric: true,
      disablePadding: false,
      label: "Product Name",
      isCustomElements: true,
      width: "25%",
      customElements: (ele: any) => {
        return ele.productName || "Product Name ";
      },
      sortable: false,
    },
    {
      id: "maxioSubscriptionId",
      numeric: true,
      disablePadding: false,
      label: "Subscription Id",
      isCustomElements: true,
      width: "22%",
      customElements: (ele: any, index: number) => {
        const subscription = maxioSubscriptions[index];

        return (
          <div className="max-w-[200px] w-full mr-5">
            <InputTextField
              name={"maxioSubscriptionId"}
              label={""}
              value={ele}
              error={{maxioSubscriptionId: ele.errorMaxioSubscriptionId}}
              type={"text"}
              onChange={() => {}}
              disabled={true}
              height={"40px"}
            />
          </div>
        );
      },
      sortable: false,
    },
    {
      id: "componentId",
      numeric: true,
      disablePadding: false,
      label: "Component Id",
      isCustomElements: true,
      width: "22%",
      customElements: (ele: any, index: number) => {
        const subscription = maxioSubscriptions[index];
        return (
          <div className="max-w-[200px] w-full mr-5">
            <InputTextField
              name={"componentId"}
              label={""}
              value={ele}
              error={{componentId: ele.errorComponentId}}
              type={"text"}
              onChange={() => {}}
              disabled={true}
              height={"40px"}
            />
          </div>
        );
      },
      sortable: false,
    },
    {
      id: "pricePointId",
      numeric: true,
      disablePadding: false,
      label: "Price Point Id",
      isCustomElements: true,
      width: "22%",
      customElements: (ele: any, index: number) => {
        const subscription = maxioSubscriptions[index];
        return (
          <div className="max-w-[200px] w-full mr-5">
            <InputTextField
              name={"pricePointId"}
              label={""}
              value={ele}
              error={{pricePointId: ele.errorPricePointId}}
              type={"text"}
              onChange={() => {}}
              disabled={true}
              height={"40px"}
            />
          </div>
        );
      },
      sortable: false,
    },
    {
      id: "find",
      numeric: true,
      disablePadding: false,
      label: "",
      isCustomElements: true,
      width: "6%",
      customElements: (ele: any) => {
        return (
          <div
            className="flex items-center cursor-pointer hover:text-[#0494B4]"
            onClick={() => {
              setCurrentProduct(ele);
              setSelectMaxioSubscriptionIDs(true);
            }}
          >
            {SearchIcon}
            <span className="ml-2">Find</span>
          </div>
        );
      },
      sortable: false,
    },
    {
      id: "delete",
      numeric: true,
      disablePadding: false,
      label: "",
      isCustomElements: true,
      width: "6%",
      customElements: (ele: any) => {
        return (
          <div
            className="cursor-pointer hover:text-[#B23352]"
            onClick={() => {
              let temp: any = [...maxioSubscriptions];
              temp = deleteItem(ele, temp);
              setMaxioSubscriptions(temp);
              setMaxioSubscriptionsData(temp);
            }}
          >
            {DeleteItemCross}
          </div>
        );
      },
      sortable: false,
    },
  ];

  const deleteItem = (ele: any, arr: any) => {
    let temp = [...arr];
    const index = temp.indexOf(ele);
    if (index > -1) {
      temp.splice(index, 1);
    }

    return temp;
  };

  const updateSubscription = (product: any, subscription: any) => {
    let updatedSubscriptions = maxioSubscriptions.map((item: any) => {
      if (item.productName === product.productName) {
        return subscription;
      }
      return item;
    });

    setMaxioSubscriptions(updatedSubscriptions);
    setMaxioSubscriptionsData(updatedSubscriptions);
  };

  useEffect(() => {
    const temp = availableProductOptions.sort()[0];
    setselectedAttribute(temp);
    setMaxioSubscriptions(maxioSubscriptionsData);
  }, [maxioSubscriptionsData, availableProductOptions]);

  const handleButtonClick = () => {
    if (userData.maxioCustomerId === null) {
      dispatch( 
        setToast({
          open: true,
          severity: "error",
          msg: "Please set the Maxio Customer Id for this user (under Details Tab)",
          autoCloseTime: 3000,
          heading: "Error",
          toastIcon: AlertTrianleIcon,
        })
      );
    } else {
      // Check if the product is already selected
      const productExists = maxioSubscriptions.some(
        (subscription: any) => subscription.productName === selectedAttribute
      );
  
      if (productExists) {
        dispatch(
          setToast({
            open: true,
            severity: "error",
            msg: "Product already selected for Maxio Ids",
            autoCloseTime: 3000,
            heading: "Error",
            toastIcon: AlertTrianleIcon,
          })
        );
      } else {
        let temp = [...maxioSubscriptions];
        let obj: any = {
          productName: selectedAttribute,
          maxioSubscriptionId: "",
          componentId: "",
          pricePointId: "",
        };
        temp.push(obj);
        setMaxioSubscriptions(temp);
        setMaxioSubscriptionsData(temp);
      }
    }
  };

  return (
    <div
      className={`${
        disable ? "pointer-events-none opacity-50 cursor-not-allowed" : ""
      }`}
    >
      <div className="flex mt-12">
        <div className="max-w-[250px] w-full mr-5">
          <DropDown
            name={"custom_attribute_defaults"}
            label={"Maxio Subscriptions"}
            value={selectedAttribute}
            error={""}
            placeholder={"Search"}
            onChange={(val: any) => {
                setselectedAttribute(val);
            }}
            required={false}
            options={availableProductOptions}
            className="!h-[40px] text-[#163545]"
          />
        </div>

        <div className="ml-auto mt-auto">
          <TrestleButton
            onClick={handleButtonClick}
            label="Find Maxio Subscription"
            type="primary"
            className="!h-[40px] !leading-[40px] !p-0 bg-[#E0EEF1] !text-[#0494B4]"
            disabled={userData.maxioCustomerId === null}
          />
        </div>
      </div>

      <div className="mt-6">
        {maxioSubscriptions.length > 0 ? (
          <TrestleTable
            isPaginated={false}
            headCells={headCellsAttributes}
            rows={maxioSubscriptions}
            hoverEffect={false}
          />
        ) : (
          ""
        )}
      </div>
      {selectMaxioSubscriptionIDs && (
        <FindMaxioSubscriptionIDs
          currentProduct={currentProduct}
          setCurrentProduct={setCurrentProduct}
          setMaxioSubscriptionsData={setMaxioSubscriptionsData}
          open={selectMaxioSubscriptionIDs}
          setPopup={setSelectMaxioSubscriptionIDs}
          maxioSubscriptionData={maxioSubscriptionsData}
          userData={userData}
          updateSubscription={updateSubscription} // Pass this function as a prop
        />
      )}
    </div>
  );
}

export default MaxioSubscriptionDetails;
