import { useState, SyntheticEvent } from "react";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { SideArrow } from "../../../assets/svgs";
import styled from "@emotion/styled";
import InputTextField from "../InputTextField";
import TrestleTable from "../TrestleTable/TrestleTable";

export default function Accordions({
  summary,
  details,
  index,
  expanded,
  setExpanded,
  disabled,
  className = "",
}: any) {
  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const panel = `panel${index}`;

  return (
    <MuiAccordion
      key={`key-accord`}
      expanded={expanded === panel}
      onChange={disabled !== null ? () => {} : handleChange(panel)}
      sx={{
        border: "none",
        marginBottom: "10px",
        boxShadow: "none",
        padding: "20px 50px 0 50px",
        cursor:
          expanded === panel
            ? "default"
            : disabled !== index && disabled !== null
            ? "not-allowed"
            : "pointer",
      }}
      className="before:!bg-inherit"
    >
      <MuiAccordionSummary
        aria-controls={`${panel}d-content`}
        id={`${panel}d-header`}
        sx={{
          height: "100px",
          bgcolor: "#ffffff",
          border: "none",
          lineHeight: "40px",
        }}
        className={`!px-0 ${
          expanded === panel
            ? "!cursor-default"
            : disabled !== index && disabled !== null
            ? "!cursor-not-allowed"
            : "!cursor-pointer"
        } ${className}`}
      >
        <span
          className={`mr-2 mb-auto mt-[15px] !cursor-pointer ${
            expanded === panel && "rotate-90"
          }`}
          onClick={() => {
            if (expanded) {
              disabled !== index && setExpanded("close");
            }
          }}
        >
          {SideArrow}
        </span>
        {summary}
      </MuiAccordionSummary>

      <MuiAccordionDetails sx={{ border: "none", padding: "0px" }}>
        {details}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
}
