import {
  Navigate,
  Route,
  Routes as RoutesReactRouterDom,
} from "react-router-dom";
import { RouteConstants } from "../constants/RouteConstants";
import { Suspense } from "react";
import YourApiKey from "../components/UserMode/YourApiKey/YourApiKey";
import YourUsage from "../components/UserMode/YourUsage/YourUsage";
import HomeComponent from "../components/Shared/HomeComponent";
import YourUsageV2 from "../components/UserMode/YourUsage/YourUsageV2";
import PricingPage from "../components/UserMode/PricingPage";

export const UserRoute = () => {
  return (
    <Suspense fallback={<p>Loading</p>}>
      <RoutesReactRouterDom>
        {/* version 2 */}
        <Route
          path={RouteConstants.ROUTE_YOUR_USAGE}
          element={<YourUsageV2 />}
        />
        {/* <Route path={RouteConstants.ROUTE_YOUR_USAGE_V2} element={<YourUsageV2 />} /> */}
        <Route
          path={RouteConstants.ROUTE_YOUR_API_KEY}
          element={<YourApiKey />}
        />
        <Route path={RouteConstants.ROUTE_HOME} element={<YourApiKey />} />
        <Route
          path={RouteConstants.ROUTE_PRICING_PAGE}
          element={<PricingPage />}
        />
        <Route
          path={"*"}
          element={<Navigate to={RouteConstants.ROUTE_HOME} />}
        />
      </RoutesReactRouterDom>
    </Suspense>
  );
};
