import React from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import {
  ComponentLabelHeader,
  Text14pxMedium,
  WalletWarningText,
} from "../Shared/CommonComponents";
import styled from "@emotion/styled";
import { ActionButtonContainer } from "../Shared/PaymentWallet/WalletComponets/Styles";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import { CreateManageSubscriptionSession } from "../Shared/PaymentWallet/Services/PaymentWalletServices";

interface BillingDetailsTabProps {
  setDialogOpen: any;
  setLoading: any;
}

const BillingDetailsTab = ({
  setDialogOpen,
  setLoading,
}: BillingDetailsTabProps) => {
  const walletData = useAppSelector((state) => state.walletReducer.walletData);
  
  const trialUser = walletData.walletDisplayType=='TRIAL' || walletData.walletDisplayType=='TRIAL_EXPIRED';
  
  // trial user, wallet exists & is from a different team but same company, block top-up
  const topUpBlocked = trialUser && walletData.walletId!=null && parseFloat(walletData.balance) != 0.0;
  
const navigate = useNavigate();
  const manageSubscriptionApiCall = async () => {
    setLoading(true);
    const response = await CreateManageSubscriptionSession();
    const { statusCode = 401, data = {} } = response;
    if (statusCode === 200) {
      window.open(data, "_self");
    }
  };
  return (
    <>
      {walletData?.walletDisplayType === "SELF_SERVE" ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <WalletWarningText c={"#163545"}>Plan</WalletWarningText>
          <PlanDetailsCardContainer>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Text14pxMedium>Self-serve</Text14pxMedium>
              <Text14pxMedium>
                {walletData?.autoRechargeEnabled
                  ? "Automatic wallet refill enabled"
                  : "Automatic wallet refill disabled"}
              </Text14pxMedium>
            </Box>
            <Box sx={{ display: "flex", gap: "20px" }}>
              <ActionButtonContainer
                u={"underline"}
                onClick={() => {
                  setDialogOpen(false);
                  navigate(RouteConstants.ROUTE_PRICING_PAGE);
                }}
              >
                Change Plan
              </ActionButtonContainer>
              <ActionButtonContainer
                u={"underline"}
                onClick={manageSubscriptionApiCall}
              >
                Manage Subscription
              </ActionButtonContainer>
            </Box>
          </PlanDetailsCardContainer>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <WalletWarningText c={"#163545"}>Plan</WalletWarningText>
          <PlanDetailsCardContainer>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <Text14pxMedium>
                {walletData?.walletDisplayType !== 'UNLIMITED'
                  ? "No Active Plan"
                  : "No Active Self-Serve Plan"}
              </Text14pxMedium>
            </Box>
            {walletData?.walletDisplayType !== 'UNLIMITED' && !topUpBlocked && (
              <Box sx={{ display: "flex", gap: "20px" }}>
                <ActionButtonContainer
                  u={"underline"}
                  onClick={() => {
                    navigate(RouteConstants.ROUTE_PRICING_PAGE);
                    setDialogOpen(false);
                  }}
                >
                  Go To Pricing
                </ActionButtonContainer>
              </Box>
            )}
          </PlanDetailsCardContainer>
        </Box>
      )}
    </>
  );
};

export const PlanDetailsCardContainer = styled.div`
  width: 100%;
  height: 70px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
`;

export default BillingDetailsTab;
